import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@backend/auth/auth.service';
import { UsersService } from '@backend/users/users.service';

@Injectable()
export class ReportsGuard implements CanActivate {

  constructor(private authSrv: AuthService,
              private userService: UsersService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getCurrentUser().then((userData) => {
      if (!userData.is_admin) {
        this.router.navigateByUrl('/personal');
        return false;
      }

      return true;
    });
  }
}
