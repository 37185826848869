import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@backend/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authSrv: AuthService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authSrv.hasSession()) {
      this.router.navigateByUrl('/');
      return false;
    } else {
      return true;
    }
  }
}
