import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@backend/auth/auth.service';
import { last as _last, forEach as _forEach } from 'lodash/fp';
import { ToastService } from '@core/toast/toast.service';
import { CustomCodes } from '@backend/custom-codes.enum';

@Component({
    selector: 'app-oauth-callback',
    templateUrl: './oauth-callback.component.html',
    styleUrls: ['./oauth-callback.component.scss']
})
export class OauthCallbackComponent implements OnInit {

    constructor(private route: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private toastService: ToastService) {
    }

    ngOnInit() {
        let accessToken = this.route.snapshot.queryParams.code || this.route.snapshot.queryParams.id_token;
        // Раньше токен слали в виде фрагмента #access_token, а не через query params,
        // на всякий случай пока оставим проверку на такой вариант
        if (!accessToken && this.route.snapshot.fragment) {
            const fragmentPairs = _last(this.route.snapshot.fragment.split('#')).split('&');
            const params = {};
            _forEach<string>((paramPair) => {
                const pair = paramPair.split('=');
                params[pair[0]] = pair[1];
            })(fragmentPairs);
            accessToken = params['access_token'];
        }

        if (accessToken) {
            this.authService.authByToken(accessToken).then((result) => {
              this.router.navigateByUrl('/personal');
            }).catch((error) => {
              if (error.error.code === CustomCodes.REGISTRATION_CLOSED) {
                this.router.navigateByUrl('/registration-closed');
              } else {
                this.router.navigateByUrl('/');
                this.toastService.sendToast({
                  type: 'error',
                  text: 'Ошибка авторизации по токену'
                });
              }
            });
        } else {
            this.router.navigateByUrl('/');
        }
    }
}
