/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./footer.component";
import * as i5 from "../backend/olympiads/olympiads.service";
import * as i6 from "@angular/router";
import * as i7 from "../backend/auth/auth.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "footer_phone fx v-center"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.website); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.website); _ck(_v, 2, 0, currVal_1); }); }
function View_FooterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "footer_phone fx v-center"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "ic_phone footer_phone_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "footer_phone_num"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "tel:", ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.phone), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.phone); _ck(_v, 3, 0, currVal_1); }); }
function View_FooterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "footer_email fx v-center"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "ic_email footer_email_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "footer_email_email"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "mailto:", ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.email), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.email); _ck(_v, 3, 0, currVal_1); }); }
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "footer_contacts"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.website); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.phone); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.email); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FooterComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "footer_nested_btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "btn btn_md btn_blue_white btn_telegram_bot"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.find_team); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.FIND_TEAM")); _ck(_v, 2, 0, currVal_1); }); }
function View_FooterComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "footer_social_item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "a", [["class", "ic_icon_vk"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.vk); _ck(_v, 1, 0, currVal_0); }); }
function View_FooterComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "footer_social_item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "a", [["class", "ic_telegram"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.telegram); _ck(_v, 1, 0, currVal_0); }); }
function View_FooterComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "footer_social_item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "a", [["class", "ic_icon_fb"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.fb); _ck(_v, 1, 0, currVal_0); }); }
function View_FooterComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "footer_social_item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "a", [["class", "ic_instagram"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.instagram); _ck(_v, 1, 0, currVal_0); }); }
function View_FooterComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "footer_social"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "ul", [["class", "footer_social_list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_7)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_9)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_10)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.vk); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.telegram); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.fb); _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.instagram); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-lg-6 col-md-6 col-sm-12 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "col-lg-6 col-md-6 col-sm-12 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "footer_social_fb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "footer_fb"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_6)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isAuth && !_co.isMainPage); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.currentOlympiad == null) ? null : _co.currentOlympiad.contacts.find_team); _ck(_v, 9, 0, currVal_1); var currVal_2 = !_co.isMainPage; _ck(_v, 11, 0, currVal_2); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 245760, null, 0, i4.FooterComponent, [i5.OlympiadsService, i6.Router, i7.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
