<div class="wrap">
    <app-header></app-header>
    <app-toast></app-toast>
    <div class="margin_top_57">
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>

<app-ui-loader></app-ui-loader>

