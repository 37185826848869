import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { LoaderInterceptorService } from '@shared/components/hse-loader/services/loader-interceptor.service';
import { finalize } from 'rxjs/internal/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth/auth.service";
import * as i2 from "./olympiads/olympiads.service";
import * as i3 from "@angular/router";
// import { HseAlertService } from '@core/hse-alert/hse-alert.service';
export class InterceptorService {
    constructor(authService, 
    /*private alert: HseAlertService*/
    olympiadService, router) {
        this.authService = authService;
        this.olympiadService = olympiadService;
        this.router = router;
    }
    intercept(req, next) {
        const authToken = this.authService.getToken();
        let headers = null;
        if (authToken) {
            headers = req.headers.set(this.authService.AUTH_TOKEN_KEY, authToken);
            if (this.olympiadService.selectedOlympiadId) {
                headers = headers.set('olympiad-id', this.olympiadService.selectedOlympiadId);
            }
        }
        const authReq = req.clone({ headers });
        /*        this.loader.interceptRequest({
                    url: authReq.url,
                    method: authReq.method
                });*/
        return next.handle(authReq).pipe(catchError((err) => {
            /*
                            this.loader.interceptError({
                              url: authReq.url,
                              method: authReq.method
                            });
            */
            if (/^(\/olympic)/i.test(authReq.url)) {
                // tslint:disable-next-line:no-magic-numbers
                if (err.status === 401 && this.authService.isAuth()) {
                    // if (err.message && err.message === 'No available olympiad') {
                    //   this.authService.logout(false);
                    //   this.router.navigateByUrl('/registration-closed');
                    // } else {
                    //   this.authService.logout();
                    // }
                    this.authService.logout();
                    // tslint:disable-next-line:no-magic-numbers
                }
                else if (err.status === 416) {
                    console.log('Нет данных');
                }
                else {
                    // tslint:disable-next-line:no-magic-numbers
                    if (err.status !== 401) {
                        // this.alert.error('Внимание! В процессе работы программы произошла ошибка. Обратитесь в техподдержку');
                    }
                    throw err;
                }
            }
            return of(err);
        }), finalize(() => {
            /*           this.loader.interceptResponse({
                         url: authReq.url,
                         method: authReq.method
                       });*/
        }));
    }
}
InterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InterceptorService_Factory() { return new InterceptorService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.OlympiadsService), i0.ɵɵinject(i3.Router)); }, token: InterceptorService, providedIn: "root" });
