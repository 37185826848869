import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Olympiad,
  OlympiadDocument,
  OlympiadFAQ,
  OlympiadPage,
  OlympiadPageType
} from '@backend/olympiads/olympiad.interface';
import { Attachment } from '@backend/attachments/attachment.interface';
import { User } from '@backend/users/user.interface';
import { ReplaySubject } from 'rxjs';
import { find as _find, reject as _reject } from 'lodash/fp';
import {YaMetrikaService} from "@core/metrika/ya-metrika.service";

@Injectable({
  providedIn: 'root'
})
export class OlympiadsService {
  BASE_URL = '/olympic/olympiads';

  SELECTED_OLYMPIAD_KEY = 'OLYMPIC-Selected-Olympiad';

  $selectedOlympiadId: ReplaySubject<string> = new ReplaySubject<string>();
  // TODO if null set as first olympiads[0] id
  selectedOlympiadId = '';

  olympiads: Olympiad[];
  selectedOlympiad: Olympiad;

  constructor(private http: HttpClient, private metrika: YaMetrikaService) {
    this.$selectedOlympiadId.subscribe((value) => {
      this.selectedOlympiadId = value;
      this.selectedOlympiad = _find<Olympiad>({_id: value})(this.olympiads);
    });
  }

  selectOlympiad(olympiad: Olympiad) {
    this.selectedOlympiad = olympiad || null;
    localStorage.setItem(this.SELECTED_OLYMPIAD_KEY, olympiad ? olympiad._id : null);
    this.$selectedOlympiadId.next(olympiad ? olympiad._id : null);
  }

  async getOlympiads(params?: {short_name: string}): Promise<Olympiad[]> {
    const httpParams = new HttpParams({ fromObject: params as any });
    if (!this.olympiads || params) {
      this.olympiads = await this.http.get<Olympiad[]>(`${this.BASE_URL}`, {params: httpParams}).toPromise();

      this.metrika.initOlympiads(this.olympiads);
      // this.olympiads = withArchived ? olympiads : _reject<Olympiad>({archived: true})(olympiads);
    }

    return this.olympiads;
  }

  getOlympiadById(id: string) {
    return this.http.get<Olympiad>(`${this.BASE_URL}/${id}`).toPromise();
  }

  async uploadFirstStepUsersData(olympiadId: string, file: Blob): Promise<User[]> {
    const formData = this.getFormData(file);

    return this.http.post<User[]>(`${this.BASE_URL}/${olympiadId}/first-step`, formData).toPromise();
  }

  async uploadSecondStepUsersData(olympiadId: string, file: Blob): Promise<User[]> {
    const formData = this.getFormData(file);

    return this.http.post<User[]>(`${this.BASE_URL}/${olympiadId}/second-step`, formData).toPromise();
  }

  async uploadStageResultUsersData(olympiadId: string, stageOrder: number, file: Blob): Promise<User[]> {
    const formData = this.getFormData(file);

    return this.http.post<User[]>(`${this.BASE_URL}/${olympiadId}/results/${stageOrder}`, formData).toPromise();
  }

  async uploadRemovedUsersData(olympiadId: string, file: Blob) {
    const formData = this.getFormData(file);

    return this.http.post<User[]>(`${this.BASE_URL}/${olympiadId}/removed-users`, formData).toPromise();
  }

  async updateFAQ(olympiadId: string, faq: OlympiadFAQ[]) {
    return this.http.put<User[]>(`${this.BASE_URL}/${olympiadId}/faq`, faq).toPromise();
  }

  async updatePages(olympiadId: string, type: OlympiadPageType, collection: OlympiadFAQ[] | OlympiadDocument[] | OlympiadPage[]) {
    return this.http.put<User[]>(`${this.BASE_URL}/${olympiadId}/data?type=${type}`, collection).toPromise();
  }

  private getFormData(file: Blob): FormData {
    const execFile: any = file;
    // поддерживаемые форматы файлов
    const fileType = /(\.csv)$/i;
    if (!fileType.exec(execFile.name)) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('file', file);

    return formData;
  }

  async getDefaultOlympiadId() {
    return this.http.get<any>(`${this.BASE_URL}/default`).toPromise();
  }
}
