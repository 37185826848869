import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-team-friends-select',
  templateUrl: './team-friends-select.component.html',
  styleUrls: ['./team-friends-select.component.scss']
})
export class TeamFriendsSelectComponent implements OnInit {

  @Input() friends = [];
  @Input() disabled = false;
  @Input() maxCount: number;
  @Output() change?: EventEmitter<any[]> = new EventEmitter();

  newFriendName: string;

  constructor() { }

  ngOnInit() {

  }

  addFriend() {
    this.friends.push({
      name: this.newFriendName
    });
    this.newFriendName = null;
    this.change.emit(this.friends);
  }

  removeFriend(idx) {
    this.friends.splice(idx, 1);
    this.change.emit(this.friends);
  }
}
