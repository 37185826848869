import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@backend/auth/auth.service';
import { UsersService } from '@backend/users/users.service';

import { isEmpty as _isEmpty } from 'lodash/fp';

@Injectable()
export class OlympiadsGuard implements CanActivate {
  constructor(private authSrv: AuthService, private usersService: UsersService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authSrv.hasSession()) {
      // const userData = await this.usersService.getCurrentUser();
      return this.usersService.getCurrentUser().then((userData) => {
        if (!_isEmpty(userData.teams)) {
          this.router.navigateByUrl('/personal');
          return false;
        }

        this.router.navigateByUrl('/olympiads/registration');
        return true;
      });
    } else {
      this.router.navigateByUrl('/');
      return false;
    }
  }
}
