import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {filter as _filter, lowerCase as _lowerCase, random as _random} from 'lodash/fp';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-single-select-autocomplete',
  templateUrl: './single-select-autocomplete.component.html',
  styleUrls: ['./single-select-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleSelectAutocompleteComponent),
      multi: true
    }
  ]
})
export class SingleSelectAutocompleteComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
  private defaultWidth = '280px';
  innerValue: string | number;

  searchValue = '';
  selected: any;
  filteredCollection: any[] = [];
  showOptionsList = false;

  @Input() collection: any[] = [];
  @Input() disabled: boolean;
  @Input() idField?: string;
  @Input() name?: string;
  @Input() nameField?: string;
  @Input() isBigList?: boolean;
  /**
   * Имя поля, которое отображается в хедере селекта
   */
  @Input() selectedNameField?: string;
  @Input() placeholder?: string;
  @Input() width?: string;
  @Input() withInput = true;
  @Input() dropdownWidth = '100%';
  @Input() dropdownClassName: string;
  @Input() withAddItemBlock: boolean;
  @Input() addItemLabel: string;
  @Input() addItemButtonLabel: string;
  @Input() loading: any;
  @Input() addCustomValue?: string;
  @Input() customValue?: any = null;
  /**
   * Флаг переопределения некоторых стилей пункта меню
   */
  @Input() overrideMenuItemStyle: boolean;

  @Output() addItem?: EventEmitter<any> = new EventEmitter();
  @Output() menuToggle?: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('inputElement', { static: true }) input: ElementRef;
  @ViewChild('selectHeader', { static: true }) selectHeader: ElementRef;

  @ViewChild(CdkVirtualScrollViewport, {static: false}) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  minValue = 1000000;
  maxValue = 9999999;
  selectId: string;

  constructor() {
    this.selectId = `${_random(this.minValue, this.maxValue)}-${Date.now()}`;
  }

  get model() {
    return this.innerValue;
  }

  set model(value) {
    this.writeValue(value);
    this.onChange(this.innerValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filterCollection(this.searchValue);

    if (!this.selected && changes.collection && this.innerValue) {
      this.writeValue(this.innerValue);
    }

    if (changes.customValue && !this.model) {
      this.setCustomValue();
    }
  }

  ngOnInit() {
    this.idField = this.idField || 'id';
    this.nameField = this.nameField || 'name';
    this.selectedNameField = this.selectedNameField || this.nameField || 'name';
    this.width = this.width || this.defaultWidth;


    // this.placeholder = 'Выбери элемент';
    this.addItemLabel = this.addItemLabel;
    this.addItemButtonLabel = this.addItemButtonLabel;

    this.setCustomValue();
  }

  setCustomValue() {
    if (!this.model && this.customValue) {
     this.selected = {
        [this.selectedNameField]: this.customValue
      };
    } else {
      this.selected = null;
    }
  }

  ngAfterViewInit() {
    // this.cdr.detectChanges();
    setTimeout(() => {
      this.dropdownWidth = this.selectHeader
        ? this.selectHeader.nativeElement.getBoundingClientRect().width + 'px'
        : null;
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value) {
    if (value === undefined) {
      return;
    }

    this.innerValue = value;
    this.selected = this.collection.find((item) => item[this.idField] === this.innerValue);
    // this.searchValue = this.selected ? this.selected[this.selectedNameField] : '';
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  optionListOpened() {
    this.showOptionsList = true;
    this.menuToggle.emit(this.showOptionsList);
    // this.selectHeader.nativeElement.style.zIndex = '5000';

    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.focus();
        if (this.cdkVirtualScrollViewport) {
          this.cdkVirtualScrollViewport.checkViewportSize();
        }
      }
    });
  }

  optionListClosed() {
    this.showOptionsList = false;
    // this.selectHeader.nativeElement.style.zIndex = '';
    this.onTouched();
    this.menuToggle.emit(this.showOptionsList);
  }

  changeInputValue(value) {
    this.filterCollection(value);
  }

  filterCollection(substr) {
    this.filteredCollection = _filter((item) => {
      return _lowerCase(item[this.nameField]).indexOf(_lowerCase(substr)) > -1;
    })(this.collection);
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport.checkViewportSize();
    }
  }

  addItemHandle() {
    this.addItem.emit(this.searchValue);
  }

  uncheck(event) {
    event.stopPropagation();
    event.preventDefault();
    this.model = null;
  }

  saveCustomValue(value) {
    this.selected = {
      [this.selectedNameField]: value
    };

    this.onChange(this.selected);
  }
}
