import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@backend/auth/auth.service';
import { UsersService } from '@backend/users/users.service';
import { isEmpty as _isEmpty } from 'lodash/fp';

@Injectable()
export class MainPageGuard implements CanActivate {
  constructor(private authSrv: AuthService, private usersService: UsersService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authSrv.hasSession()) {
      // const userData = await this.usersService.getCurrentUser();
      return this.usersService.getCurrentUser().then((userData) => {
        this.router.navigateByUrl('/personal');

        // if (!_isEmpty(userData.teams)) {
        //   this.router.navigateByUrl('/personal');
        // } else {
        //   this.router.navigateByUrl('/olympiads');
        // }

        return false;
      });
    } else {
      return true;
    }
  }
}
