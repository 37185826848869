import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements OnInit, ControlValueAccessor {
  private innerValue: Date;
  displayDate: string;
  currentDay = new Date();

  @Input() disabled: boolean;

  get model(): Date {
    return this.innerValue;
  }

  set model(val) {
    this.innerValue = val;
    this.onChange(this.innerValue);
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  writeValue(val) {
    this.displayDate = moment(val).format('DD.MM.YYYY');
    this.innerValue = val;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  constructor() {
  }

  datePickerChanged($event) {
    this.displayDate = moment($event.value).format('DD.MM.YYYY');
  }

  dateInputBlur($event) {
    this.model = moment(this.displayDate, 'DD.MM.YYYY').startOf('day').toDate();
  }

  ngOnInit() {
  }
}
