import * as tslib_1 from "tslib";
import { isUndefined as _isUndefined } from 'lodash/fp';
import { forEach as _forEach } from 'lodash/fp';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../olympiads/olympiads.service";
export class SettingsService {
    constructor(http, olympiadService) {
        this.http = http;
        this.olympiadService = olympiadService;
        this.BASE_URL = '/olympic/settings';
        this.DEFAULT_SETTINGS = {
            olympiad_id: null,
            registration_available: true,
            video_interview_available: true,
            team_edit_available: true,
            results_tab_available: false
            // second_step_task_upload_available: false
        };
    }
    getSettingsList() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const settingsList = yield this.http.get(`${this.BASE_URL}`).toPromise();
            _forEach((settings) => {
                this.fillDefaultSettingValues(settings);
            })(settingsList);
            return settingsList;
        });
    }
    // TODO olympiadId
    getSettings(olympiadId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.http.get(`${this.BASE_URL}/${olympiadId}`).toPromise();
            this.fillDefaultSettingValues(result);
            return result;
        });
    }
    getCurrentSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const olympiadId = this.olympiadService.selectedOlympiadId;
            if (!olympiadId) {
                return null;
            }
            return this.getSettings(olympiadId);
        });
    }
    updateSettings(settingsData) {
        return this.http.put(`${this.BASE_URL}`, settingsData);
    }
    fillDefaultSettingValues(settings) {
        if (_isUndefined(settings.video_interview_available)) {
            settings.video_interview_available = this.DEFAULT_SETTINGS.video_interview_available;
        }
        if (_isUndefined(settings.team_edit_available)) {
            settings.team_edit_available = this.DEFAULT_SETTINGS.team_edit_available;
        }
        // if (_isUndefined(settings.second_step_task_upload_available)) {
        //   settings.second_step_task_upload_available = this.DEFAULT_SETTINGS.second_step_task_upload_available;
        // }
    }
}
SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.OlympiadsService)); }, token: SettingsService, providedIn: "root" });
