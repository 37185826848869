<ng-container *ngIf="isShow">
    <div class="toast_wrap {{tType}}" [style.width.px]="tWidth">
        <div class="fx toast_container toast_type">
            <div class="toast_icon"></div>
            <div class="toast_text">
                {{tText}}
            </div>
            <div class="toast_icon_close" (click)="close()"></div>
        </div>
    </div>
</ng-container>
