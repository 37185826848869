import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppLoadService } from './app-load.service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InterceptorService } from '@backend/interceptor.service';
import { UiLoaderInterceptorService } from '@core/ui-loader/services/ui-loader-interceptor.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
// import {MetrikaModule} from 'ng-yandex-metrika';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // MetrikaModule.forRoot([])
  ],
  providers: [
    AppLoadService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appLoader: AppLoadService) => {
        return () => appLoader.initApp();
      },
      deps: [AppLoadService],
      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UiLoaderInterceptorService, multi: true},
    {provide: 'googleTagManagerId', useValue: 'GTM-P6DCQX'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
