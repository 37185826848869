import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { VkCity } from '@backend/vk-proxy/vk-city.interface';
import { VkRegion } from '@backend/vk-proxy/vk-region.interface';
import { MailForm } from '@backend/support/mail-form.interface';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  BASE_URL = '/olympic/support';

  constructor(private http: HttpClient) {
  }

  sendMessage(mailFormData?: MailForm) {
    // @ts-ignore
    return this.http.post<VkCity>(this.BASE_URL + '/mail', {...mailFormData}).toPromise();
  }

}
