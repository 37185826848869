import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackendModule } from '@backend/backend.module';
import { HeaderComponent } from '@core/header/header.component';
import { SharedModule } from '@shared/shared.module';
import { ToastService } from '@core/toast/toast.service';
import { ToastComponent } from '@core/toast/toast.component';
import { MainPageGuard } from '@core/guards/main-page-guard.service';
import { OlympiadsGuard } from '@core/guards/olympiads-guard.service';
import { AuthGuard } from '@core/guards/auth-guard.service';
import { UiLoaderModule } from '@core/ui-loader/ui-loader.module';
import { FooterComponent } from './footer/footer.component';
import { ReportsGuard } from '@core/guards/reports-guard.service';
import { TeamEditorGuard } from '@core/guards/team-editor-guard.service';
import { AdminGuard } from '@core/guards/admin-guard.service';
import { OlympiadSelectorComponent } from './olympiad-selector/olympiad-selector.component';
import { YaMetrikaService } from '@core/metrika/ya-metrika.service';

@NgModule({
  declarations: [
    HeaderComponent,
    ToastComponent,
    FooterComponent,
    OlympiadSelectorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    BackendModule,
    UiLoaderModule
  ],
  exports: [
    HeaderComponent,
    ToastComponent,
    BackendModule,
    UiLoaderModule,
    FooterComponent
  ],
  providers: [
    ToastService,
    YaMetrikaService,
    AuthGuard,
    MainPageGuard,
    OlympiadsGuard,
    ReportsGuard,
    TeamEditorGuard,
    AdminGuard
  ]
})
export class CoreModule { }
