import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../toast/toast.service";
import * as i3 from "@ngx-translate/core";
export class AttachmentsService {
    constructor(http, toastService, translateService) {
        this.http = http;
        this.toastService = toastService;
        this.translateService = translateService;
        this.SIZE_LIMIT = 15 * 1024 * 1024;
        this.url = '/olympic/attachments';
    }
    create(file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (file.size > this.SIZE_LIMIT) {
                this.toastService.sendToast({
                    type: 'error',
                    text: yield this.translateService.get('COMMON.FILE_LIMIT_ALERT').toPromise(),
                    width: 400
                });
                return;
            }
            const execFile = file;
            // поддерживаемые форматы файлов
            const fileType = /(\.jpg|\.jpeg|\.jpe|\.png|\.svg|\.svgz|\.mp3|\.doc|\.docx|\.pdf)$/i;
            // this.graduationYear.reset();
            if (!fileType.exec(execFile.name)) {
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            return this.http.post(this.url, formData).toPromise();
        });
    }
}
AttachmentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AttachmentsService_Factory() { return new AttachmentsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.TranslateService)); }, token: AttachmentsService, providedIn: "root" });
