/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./oauth-callback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./oauth-callback.component";
import * as i3 from "@angular/router";
import * as i4 from "../auth/auth.service";
import * as i5 from "../../toast/toast.service";
var styles_OauthCallbackComponent = [i0.styles];
var RenderType_OauthCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OauthCallbackComponent, data: {} });
export { RenderType_OauthCallbackComponent as RenderType_OauthCallbackComponent };
export function View_OauthCallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "oauth-callback"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Authorization..."]))], null, null); }
export function View_OauthCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-oauth-callback", [], null, null, null, View_OauthCallbackComponent_0, RenderType_OauthCallbackComponent)), i1.ɵdid(1, 114688, null, 0, i2.OauthCallbackComponent, [i3.ActivatedRoute, i3.Router, i4.AuthService, i5.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OauthCallbackComponentNgFactory = i1.ɵccf("app-oauth-callback", i2.OauthCallbackComponent, View_OauthCallbackComponent_Host_0, {}, {}, []);
export { OauthCallbackComponentNgFactory as OauthCallbackComponentNgFactory };
