import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UiLoaderService {

    private loaderIsVisible = false;
    private loadingStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Получить статус загрузки
     */
    get loadingStatus(): Observable<boolean> {
        return this.loadingStatus$.asObservable();
    }

    /**
     * Показать лоадер
     */
    show() {
        this.loading = true;
    }

    /**
     * Скрыть лоадер
     */
    hide() {
        this.loading = false;
    }

    /**
     * Установить видимость лоадера
     */
    private set loading(value: boolean) {
        this.loaderIsVisible = value;
        this.loadingStatus$.next(value);
    }
}
