import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@backend/auth/auth.service';
import { UsersService } from '@backend/users/users.service';
import { OlympiadsService } from '@backend/olympiads/olympiads.service';
import { find as _find, getOr as _getOr } from 'lodash/fp';
import { OlympiadUserData } from '@backend/users/user.interface';

@Injectable()
export class TeamEditorGuard implements CanActivate {
  constructor(private authSrv: AuthService,
              private usersService: UsersService,
              private router: Router,
              private olympiadService: OlympiadsService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authSrv.hasSession()) {
      // const userData = await this.usersService.getCurrentUser();
      return this.usersService.getCurrentUser().then((userData) => {
        // TODO GET CURRENT OLYMPIAD FROM SERVICE
        const currentUserOlympiadData = _find<OlympiadUserData>({
          olympiad: this.olympiadService.selectedOlympiadId
        })(userData.olympiads);

        if (!currentUserOlympiadData || !_getOr(null, 'checked')(currentUserOlympiadData.stages[0])) {
          this.router.navigateByUrl('/personal');
          return false;
        }

        return true;
      });
    } else {
      this.router.navigateByUrl('/');
      return false;
    }
  }
}
