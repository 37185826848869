const ADFS = 'https://auth-test.hse.ru/adfs/oauth2';
const AUTH_POINT = 'https://lk.hse.ru';

export const environment = {
  production: false,
  redirectURI: 'https://devolympreg.hse.ru/callback',
  CLIENT_ID: 'd782d0b5-1fcb-431a-8eea-a0c9c760cc95', // 'ea8f4a7a-f678-43ef-9434-f595c3459863'
  ADFS,
  AUTHORIZE: ADFS + '/authorize',
  LOGOUT: ADFS + '/logout',
  AUTH_POINT,
  REGISTRATION: AUTH_POINT + '/registration'
};
