import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SupportService {
    constructor(http) {
        this.http = http;
        this.BASE_URL = '/olympic/support';
    }
    sendMessage(mailFormData) {
        // @ts-ignore
        return this.http.post(this.BASE_URL + '/mail', Object.assign({}, mailFormData)).toPromise();
    }
}
SupportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SupportService_Factory() { return new SupportService(i0.ɵɵinject(i1.HttpClient)); }, token: SupportService, providedIn: "root" });
