import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ControlValueAccessor } from '@angular/forms';
import { find as _find, reject as _reject, filter as _filter, flow as _flow, concat as _concat } from 'lodash/fp';
export class UserSearchSelectComponent {
    constructor(userService) {
        this.userService = userService;
        this.userChanged = new EventEmitter();
        this.searchString$ = new Subject();
        this.users = [];
        this.searchResults = [];
        this.page = 1;
        this.perPage = 100;
        this.totalPages = 10;
        this.onChange = () => { };
        this.onTouched = () => { };
    }
    get model() {
        return this.selectedUser;
    }
    set model(value) {
        this.selectedUser = value;
    }
    ngOnChanges(changes) {
    }
    ngOnInit() {
        this.searchString$
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((searchString) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.users = [];
            this.searchResults = [];
            this.page = 1;
            this.searchString = searchString;
            yield this.getSearchPage();
            this.processFreeAndUnavailableUsers();
        }));
    }
    getSearchPage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            const subjectFilterParam = this.subjectKey ? { subject_key: this.subjectKey } : {};
            const searchResponse = this.searchString
                ? yield this.userService.searchUsers(Object.assign({ without_team: true, olympiad_id: this.olympiadId, search_string: this.searchString, page: this.page, per_page: this.perPage }, subjectFilterParam))
                : [];
            // @ts-ignore
            this.searchResults = this.searchResults.concat(searchResponse.users);
            // @ts-ignore
            this.totalPages = Number(searchResponse.totalPages);
            this.isLoading = false;
            // @ts-ignore
            return searchResponse.users;
        });
    }
    processFreeAndUnavailableUsers() {
        // console.log('processFreeAndUnavailableUsers', this.unavailableUserIds, this.freeUsers);
        // убираем полоьзователей которые уже выбраны, но не сохранены на бэке
        const filteredUnavalableUsers = _reject((user) => {
            return this.unavailableUserIds.includes(user._id);
        })(this.searchResults);
        // добавляем пользователей, которые уже доступны, но не сохранены на бэке, фильтруем по предмету
        this.users = _flow(_filter((user) => {
            const currentOlympiadUserData = _find({ olympiad: this.olympiadId })(user.olympiads);
            const userTextData = `${user.family_name} ${user.given_name || ''} ${user.middlename || ''} ${user.email}`;
            return (!this.subjectKey || currentOlympiadUserData.subject_key === this.subjectKey)
                && userTextData.indexOf(this.searchString) > -1;
        }), _concat(filteredUnavalableUsers))(this.freeUsers);
    }
    writeValue(value) {
        this.model = value;
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    searchStringChanged($event) {
        this.searchString$.next($event);
    }
    selectUser(user) {
        this.model = user;
        this.userChanged.emit(user);
        // this.users = [];
    }
    optionListClosed() {
        // this.users = [];
    }
    optionListOpened() {
        this.processFreeAndUnavailableUsers();
    }
    checkScrolled($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isLoading) {
                return;
            }
            const range = this.viewport.getRenderedRange();
            if (range.end > 0 && (range.end === this.users.length) && this.page < this.totalPages) {
                this.page += 1;
                const page = yield this.getSearchPage();
                this.users = this.users.concat(page);
            }
        });
    }
}
