import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserAgreementsService {
    constructor(http) {
        this.http = http;
        this.BASE_URL = '/olympic/user-agreement';
    }
    getUserAgreements(params) {
        const httpParams = new HttpParams({ fromObject: params });
        return this.http.get(`${this.BASE_URL}`, { params: httpParams }).toPromise();
    }
    createUserAgreement(userAgreementData) {
        return this.http.post(`${this.BASE_URL}`, userAgreementData).toPromise();
    }
    updateUserAgreement(id, userAgreementData) {
        return this.http.put(`${this.BASE_URL}/${id}`, userAgreementData).toPromise();
    }
}
UserAgreementsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAgreementsService_Factory() { return new UserAgreementsService(i0.ɵɵinject(i1.HttpClient)); }, token: UserAgreementsService, providedIn: "root" });
