import { Team } from '@backend/teams/team.interface';
import { UserStatus } from '@backend/users/user-status.enum';
import { EducationLevel } from '@backend/users/education-level.enum';
import { Subjects } from '@backend/teams/subjects.enum';
import {Olympiad, OlympiadStage} from '@backend/olympiads/olympiad.interface';

import { map as _map } from 'lodash/fp';

export enum UserSex {
  FEMALE = 0,
  MALE = 1
}

export interface User {
  _id?: string;
  // phone: string;
  commonname: string;
  middlename: string;
  family_name: string;
  given_name: string;
  email: string;
  teams: string[] | Team[];
  is_admin?: boolean;
  olympiads: OlympiadUserData[];

  fullName?: string;
  created_at: number;
  created_elk_at: number;
  birthdate: any;
  sex: UserSex;
}

export interface OlympiadUserData {
  is_initial: boolean;
  _id?: string;
  phone: string;
  // субъект РФ
  region: string;
  // город
  city: string;
  city_id: number;
  // стаж
  experience: number;
  organization: string;
  organization_id: number;
  organization_city: string;
  organization_city_id: number;
  organization_old_country_id?: number;
  organization_city_region: string;

  faculty: string;
  faculty_id: number;

  chair: string;
  chair_id: number;
  // organization_city_region_id: number;

  status: UserStatus;
  education_level?: EducationLevel;
  was_magistracy?: boolean;
  education_status?: number;
  custom_education_status?: string;
  class_level?: number;
  class_unit?: number;
  class_unit_task?: number;
  class_unit_final?: boolean;
  graduation_year?: string;
  country?: number;
  country_name?: string;
  subject_key: Subjects;
  olympiad: string | Olympiad;
  update_date: number;
  direction: number | number[];
  stages: StageUserData[];
  created_at: number;
  agreement_checked: boolean;
  rules_agreement_checked: boolean;
  email_rules_checked: boolean;
  public_checked: boolean;
  team_friends?: [{
    name: string;
  }];
  post_index: number;
  post_address: string;
  telegram_account: string;
  reg_id: string;
}

export interface FirstStepUserData {
  direction_scores: number;
  teacher_case_scores: number;
  total_scores: number;
}

export interface SecondStepUserData {
  project_team_scores: number;
}

export interface StageUserData {
  order: number;
  checked: boolean;
  results: number[];
  direction?: number;
  next_stage_membership?: boolean; // UDF.NEXT_STAGE_MEMBERSHIP_CHECKBOX
}

export class UserData implements User {
  // tslint:disable-next-line:variable-name
  _id: string;
  // phone: string;
  commonname: string;
  middlename: string;
  // tslint:disable-next-line:variable-name
  family_name: string;
  // tslint:disable-next-line:variable-name
  given_name: string;
  email: string;
  teams: string[] | Team[];
  // tslint:disable-next-line:variable-name
  is_admin?: boolean;
  olympiads: OlympiadUserData[];
  fullName?: string;
  // tslint:disable-next-line:variable-name
  created_at: number;
  // tslint:disable-next-line:variable-name
  created_elk_at: number;
  birthdate: any;
  sex: UserSex;
  constructor(private data: UserCompact) {
    this._id = data._id;
    this.commonname = data.c;
    this.middlename = data.m;
    this.family_name = data.f;
    this.given_name = data.g;
    this.email = data.e;
    this.teams = data.teams;
    this.is_admin = data.ia;
    this.created_at = data.ca;
    this.created_elk_at = data.cea;
    this.birthdate = data.b;
    this.sex = data.s;
    this.olympiads = _map<OlympiadUserDataCompact, OlympiadUserData>(olympiad => Object({
      is_initial: olympiad.ii,
      _id: olympiad._id,
      phone: olympiad.p,
      region: olympiad.r,
      city: olympiad.c,
      city_id: olympiad.ci,
      experience: olympiad.e,
      organization: olympiad.o,
      organization_id: olympiad.oi,
      organization_city: olympiad.oc,
      organization_city_id: olympiad.oci,
      organization_old_country_id: olympiad.ooci,
      organization_city_region: olympiad.ocr,
      faculty: olympiad.f,
      faculty_id: olympiad.fi,
      chair: olympiad.ch,
      chair_id: olympiad.chi,
      // organization_city_region_id: olympiad.ocri,
      status: olympiad.s,
      education_level: olympiad.el,
      education_status: olympiad.es,
      custom_education_status: olympiad.ces,
      class_level: olympiad.cl,
      class_unit: olympiad.cu,
      class_unit_task: olympiad.cut,
      graduation_year: olympiad.gy,
      country: olympiad.co,
      country_name: olympiad.cn,
      subject_key: olympiad.sk,
      olympiad: olympiad.ol,
      update_date: olympiad.u,
      direction: olympiad.d,
      created_at: olympiad.ca,
      agreement_checked: olympiad.ac,
      rules_agreement_checked: olympiad.rac,
      email_rules_checked: olympiad.erc,
      public_checked: olympiad.pc,
      team_friends: olympiad.tf,
      post_index: olympiad.pi,
      post_address: olympiad.pa,
      telegram_account: olympiad.ta,
      reg_id: olympiad.ri,
      was_magistracy: olympiad.wm,
      class_unit_final: olympiad.cuf,
      stages: _map<StageUserDataCompact, StageUserData>((stage) => Object({
        order: stage.o,
        checked: stage.c,
        results: stage.r,
        direction: stage.d,
        next_stage_membership: stage.nsm,
      }))(olympiad.stages),
    }))(data.olympiads);
  }

  static compact(data: User): UserCompact {
    return {
      _id: data._id,
      c: data.commonname,
      m: data.middlename,
      f: data.family_name,
      g: data.given_name,
      e: data.email,
      teams: data.teams,
      ia: data.is_admin,
      ca: data.created_at,
      cea: data.created_elk_at,
      b: data.birthdate,
      s: data.sex,
      olympiads: _map<OlympiadUserData, OlympiadUserDataCompact>((olympiad) => Object({
          ii: olympiad.is_initial,
          _id: olympiad._id,
          p: olympiad.phone,
          r: olympiad.region,
          c: olympiad.city,
          ci: olympiad.city_id,
          e: olympiad.experience,
          o: olympiad.organization,
          oi: olympiad.organization_id,
          oc: olympiad.organization_city,
          oci: olympiad.organization_city_id,
          ooci: olympiad.organization_old_country_id,
          ocr: olympiad.organization_city_region,
          f: olympiad.faculty,
          fi: olympiad.faculty_id,
          ch: olympiad.chair,
          chi: olympiad.chair_id,
          // ocri: olympiad.organization_city_region_id,
          s: olympiad.status,
          el: olympiad.education_level,
          es: olympiad.education_status,
          ces: olympiad.custom_education_status,
          cl: olympiad.class_level,
          cu: olympiad.class_unit,
          cut: olympiad.class_unit_task,
          gy: olympiad.graduation_year,
          co: olympiad.country,
          cn: olympiad.country_name,
          sk: olympiad.subject_key,
          ol: olympiad.olympiad,
          u: olympiad.update_date,
          d: olympiad.direction,
          ca: olympiad.created_at,
          ac: olympiad.agreement_checked,
          rac: olympiad.rules_agreement_checked,
          erc: olympiad.email_rules_checked,
          pc: olympiad.public_checked,
          tf: olympiad.team_friends,
          pi: olympiad.post_index,
          pa: olympiad.post_address,
          ta: olympiad.telegram_account,
          ri: olympiad.reg_id,
          cuf: olympiad.class_unit_final,
          wm: olympiad.was_magistracy,
          stages: _map<StageUserData, StageUserDataCompact>((stage) => Object({
            o: stage.order,
            c: stage.checked,
            r: stage.results,
            d: stage.direction,
            nsm: stage.next_stage_membership
          }))(olympiad.stages),
      }))(data.olympiads)
    };
  }
}

export interface UserCompact {
  _id: string;
  e: string;
  g: string;
  f: string;
  c: string;
  m: string;
  teams: string[] | Team[];
  ia?: boolean;
  olympiads: OlympiadUserDataCompact[];
  ca: number;
  cea: number;
  b: string;
  s: UserSex;
}

export interface OlympiadUserDataCompact {
  ii: boolean;
  _id?: string;
  sk: Subjects; // subject_key
  ol: Olympiad; // olympiad
  stages: StageUserDataCompact[]; // stages
  d: number | number[]; // direction
  p: string; // phone
  r: string; // region
  c: string; // city
  ci: number; // city
  e: number; // experience
  o: string; // organization
  oi: number; // organization_id
  oc: string; // organization_city
  oci: number; // organization_city_id
  ooci: number; // organization_old_country_id
  ocr: string; // organization_city_region
  // ocri: number; // organization_city_region_id
  f: string; // faculty
  fi: number; // faculty_id
  ch: string; // chair
  chi: number; // chair_id
  s: UserStatus; // status
  el?: EducationLevel; // education_level
  es?: number; // education_status
  ces?: string; // custom_education_status
  cl?: number; // class_level
  cu?: number; // class_unit
  cut?: number; // class_unit_task
  gy?: string; // graduation_year
  co?: number; // country
  cn?: string; // country_name
  u: number; // update_date
  oa?: boolean; // is_admin
  ca: number; // created_at
  ac: boolean; // agreement_checked
  rac: boolean; // rules_agreement_checked
  erc: boolean; // email_rules_checked
  pc: boolean; // public_checked
  ri: number; // reg_id
  tf?: [{
    name: string;
  }]; // team_friends
  pi: number; // post_index
  pa: string; // post_address
  ta: string; // telegram_account
  wm: boolean; // was_magistracy
  cuf: boolean; // class_unit_final
}

export interface StageUserDataCompact {
  o: number; // order
  c: boolean; // checked
  r: number[]; // results
  d?: number; // direction
  nsm?: boolean; // next_stage_membership
}
