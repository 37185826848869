import { Component, OnInit } from '@angular/core';
import { AuthService } from '@backend/auth/auth.service';
import { UsersService } from '@backend/users/users.service';
import { User } from '@backend/users/user.interface';
import { OlympiadsService } from '@backend/olympiads/olympiads.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser: User;

  constructor(public authService: AuthService,
              private userService: UsersService,
              public olympiadService: OlympiadsService) {
  }

  async ngOnInit() {
    // if (this.authService.hasSession()) {
    //   this.currentUser = await this.userService.getCurrentUser();
    // }

    this.authService.session$.subscribe(async (session) => {
      this.currentUser = session ? await this.userService.getCurrentUser() : null;
    });

    // this.signUp();
  }

  signIn() {
    this.authService.authorize();
  }

  signUp() {
    this.authService.registration();
  }

  restorePassword() {
    this.authService.restore();
  }
}
