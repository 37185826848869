import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OauthCallbackComponent } from '@backend/oauth-callback/oauth-callback.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/main/main.module').then((m) => m.MainModule)
  },
  {
    path: 'olympiads',
    loadChildren: () => import('./modules/olympiads/olympiads.module').then((m) => m.OlympiadsModule)
  },
  {
    path: 'personal',
    loadChildren: () => import('./modules/personal-area/personal-area.module').then((m) => m.PersonalAreaModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule)
  },
  {
    path: 'upload-approved-users',
    loadChildren: () => import('./modules/user-olympiad-approve/user-olympiad-approve.module').then((m) => m.UserOlympiadApproveModule)
  },
  {
    path: 'callback',
    component: OauthCallbackComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
