import { forEach as _forEach } from 'lodash/fp';
import * as i0 from "@angular/core";
/* tslint:disable */
export class YaMetrikaService {
    constructor() {
        this.isInited = false;
    }
    init() {
        if (this.isInited) {
            return;
        }
        // @ts-ignore
        (function (m, e, t, r, i, k, a) {
            m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments); };
            // @ts-ignore
            m[i].l = 1 * new Date();
            k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
        })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
        this.ym = window['ym'];
        this.isInited = true;
    }
    initOlympiad(metrikaId) {
        if (!this.isInited) {
            return;
        }
        this.ym(metrikaId, "init", {});
    }
    initOlympiads(olympiads) {
        if (!this.isInited) {
            return;
        }
        _forEach((olympiad) => {
            if (olympiad.contacts.metrika) {
                console.log(olympiad.short_name);
                this.initOlympiad(olympiad.contacts.metrika.id);
            }
        })(olympiads);
    }
    reachGoal(olympiad, goal) {
        if (!this.isInited) {
            return;
        }
        this.ym(olympiad.contacts.metrika.id, 'reachGoal', goal);
    }
}
YaMetrikaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YaMetrikaService_Factory() { return new YaMetrikaService(); }, token: YaMetrikaService, providedIn: "root" });
