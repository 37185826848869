import {Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import {UiLoaderService} from '@core/ui-loader/services/ui-loader.service';

@Component({
  selector: 'app-ui-loader',
  templateUrl: './ui-loader.component.html',
  styleUrls: ['./ui-loader.component.scss']
})
export class UiLoaderComponent implements OnInit, OnDestroy {
  private subscription = Subscription.EMPTY;

  /**
   * Открытие/скрытие лоадера
   */
  visible = true;

  constructor(private uiLoaderSrv: UiLoaderService) { }

  ngOnInit() {
    // console.log(this.visible);

    this.subscription = this.uiLoaderSrv.loadingStatus
        .pipe(delay(500))
        .subscribe((v) => {
          this.visible = v;
        });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
