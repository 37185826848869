/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./olympiad-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i3 from "@angular/material/menu";
import * as i4 from "@angular/common";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "./olympiad-selector.component";
import * as i9 from "../backend/olympiads/olympiads.service";
import * as i10 from "../backend/users/users.service";
var styles_OlympiadSelectorComponent = [i0.styles];
var RenderType_OlympiadSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OlympiadSelectorComponent, data: {} });
export { RenderType_OlympiadSelectorComponent as RenderType_OlympiadSelectorComponent };
function View_OlympiadSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "olympiad-item mat-menu-item"], ["mat-menu-item", ""]], [[2, "selected", null], [1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.selectOlympiad(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatMenuItem_0, i2.RenderType_MatMenuItem)), i1.ɵdid(1, 180224, [[1, 4], [2, 4]], 0, i3.MatMenuItem, [i1.ElementRef, i4.DOCUMENT, i5.FocusMonitor, [2, i3.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "fx column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit._id === _co.olympiadService.selectedOlympiadId); var currVal_1 = i1.ɵnov(_v, 1).role; var currVal_2 = i1.ɵnov(_v, 1)._highlighted; var currVal_3 = i1.ɵnov(_v, 1)._triggersSubmenu; var currVal_4 = i1.ɵnov(_v, 1)._getTabIndex(); var currVal_5 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_6 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_7); }); }
export function View_OlympiadSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "span", [["aria-haspopup", "true"], ["class", "olympiad-title mat-menu-trigger"]], [[1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 1196032, null, 0, i3.MatMenuTrigger, [i6.Overlay, i1.ElementRef, i1.ViewContainerRef, i3.MAT_MENU_SCROLL_STRATEGY, [2, i3.MatMenu], [8, null], [2, i7.Directionality], i5.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "mat-menu", [], null, null, null, i2.View__MatMenu_0, i2.RenderType__MatMenu)), i1.ɵprd(6144, null, i3.MatMenu, null, [i3._MatMenu]), i1.ɵprd(6144, null, i3.MAT_MENU_PANEL, null, [i3.MatMenu]), i1.ɵdid(6, 1294336, [["menu", 4]], 3, i3._MatMenu, [i1.ElementRef, i1.NgZone, i3.MAT_MENU_DEFAULT_OPTIONS], { xPosition: [0, "xPosition"], yPosition: [1, "yPosition"], overlapTrigger: [2, "overlapTrigger"] }, null), i1.ɵqud(603979776, 1, { _allItems: 1 }), i1.ɵqud(603979776, 2, { items: 1 }), i1.ɵqud(603979776, 3, { lazyContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_OlympiadSelectorComponent_1)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 6); _ck(_v, 1, 0, currVal_1); var currVal_3 = "after"; var currVal_4 = "below"; var currVal_5 = false; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.olympiads; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).menuOpen || null); _ck(_v, 0, 0, currVal_0); var currVal_2 = ((_co.olympiadService == null) ? null : ((_co.olympiadService.selectedOlympiad == null) ? null : _co.olympiadService.selectedOlympiad.name)); _ck(_v, 2, 0, currVal_2); }); }
export function View_OlympiadSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-olympiad-selector", [], null, null, null, View_OlympiadSelectorComponent_0, RenderType_OlympiadSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i8.OlympiadSelectorComponent, [i9.OlympiadsService, i10.UsersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OlympiadSelectorComponentNgFactory = i1.ɵccf("app-olympiad-selector", i8.OlympiadSelectorComponent, View_OlympiadSelectorComponent_Host_0, {}, {}, []);
export { OlympiadSelectorComponentNgFactory as OlympiadSelectorComponentNgFactory };
