import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class ToastService {
    constructor() {
        this.subject = new Subject();
    }
    sendToast(data) {
        this.subject.next(data);
    }
    getToast() {
        return this.subject.asObservable();
    }
}
ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
