import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '@backend/auth/auth.service';
import {Router} from '@angular/router';
// import { LoaderInterceptorService } from '@shared/components/hse-loader/services/loader-interceptor.service';
import {finalize} from 'rxjs/internal/operators';
import {OlympiadsService} from "@backend/olympiads/olympiads.service";

// import { HseAlertService } from '@core/hse-alert/hse-alert.service';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService,
              /*private alert: HseAlertService*/
              private olympiadService: OlympiadsService,
              private router: Router,
              /*private loader: LoaderInterceptorService*/) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.getToken();

    let headers = null;
    if (authToken) {
      headers = req.headers.set(this.authService.AUTH_TOKEN_KEY, authToken);

      if (this.olympiadService.selectedOlympiadId) {
        headers = headers.set('olympiad-id', this.olympiadService.selectedOlympiadId);
      }
    }



    const authReq = req.clone({headers});

    /*        this.loader.interceptRequest({
                url: authReq.url,
                method: authReq.method
            });*/

    return next.handle(authReq).pipe(
      catchError((err) => {
        /*
                        this.loader.interceptError({
                          url: authReq.url,
                          method: authReq.method
                        });
        */
        if (/^(\/olympic)/i.test(authReq.url)) {
          // tslint:disable-next-line:no-magic-numbers
          if (err.status === 401 && this.authService.isAuth()) {
            // if (err.message && err.message === 'No available olympiad') {
            //   this.authService.logout(false);
            //   this.router.navigateByUrl('/registration-closed');
            // } else {
            //   this.authService.logout();
            // }
            this.authService.logout();
            // tslint:disable-next-line:no-magic-numbers
          } else if (err.status === 416) {
            console.log('Нет данных');
          } else {
            // tslint:disable-next-line:no-magic-numbers
            if (err.status !== 401) {
              // this.alert.error('Внимание! В процессе работы программы произошла ошибка. Обратитесь в техподдержку');
            }

            throw err;
          }
        }

        return of(err);
      }),
      finalize(() => {
        /*           this.loader.interceptResponse({
                     url: authReq.url,
                     method: authReq.method
                   });*/
      })
    );

  }


}
