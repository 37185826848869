import {
  Component, ElementRef, EventEmitter, forwardRef, Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { VkProxyService } from '@backend/vk-proxy/vk-proxy.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { VkCity } from '@backend/vk-proxy/vk-city.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-vk-city-select',
  templateUrl: './vk-city-select.component.html',
  styleUrls: ['./vk-city-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VkCitySelectComponent),
    }
  ]
})
export class VkCitySelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() width: string;
  @Input() disabled: boolean;
  @Input() countryId;
  @Output() cityChanged = new EventEmitter<VkCity>();

  @ViewChild('input', { static: true }) input: ElementRef;

  searchString$: Subject<string> = new Subject<string>();
  searchString: string;
  selectedCity: string;
  cities: VkCity[];

  get model() {
    return this.selectedCity;
  }

  set model(value) {
    this.selectedCity = value;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private vkProxyService: VkProxyService ) { }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnInit() {
    this.searchString$
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe(async (searchString) => {
        this.cities = searchString
          ? await this.vkProxyService.getCities({ country_id: this.countryId || 1, q: searchString })
          : [];
      });
  }

  writeValue(value) {
    this.model = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  searchStringChanged($event) {
    this.searchString$.next($event);
  }

  selectCity(city: VkCity) {
    this.model = city.title;
    this.cityChanged.emit(city);
  }

  optionListClosed() {

  }

  optionListOpened() {

  }
}
