/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modules/core/header/header.component.ngfactory";
import * as i3 from "./modules/core/header/header.component";
import * as i4 from "./modules/core/backend/auth/auth.service";
import * as i5 from "./modules/core/backend/users/users.service";
import * as i6 from "./modules/core/backend/olympiads/olympiads.service";
import * as i7 from "./modules/core/toast/toast.component.ngfactory";
import * as i8 from "./modules/core/toast/toast.component";
import * as i9 from "./modules/core/toast/toast.service";
import * as i10 from "@angular/router";
import * as i11 from "./modules/core/footer/footer.component.ngfactory";
import * as i12 from "./modules/core/footer/footer.component";
import * as i13 from "./modules/core/ui-loader/components/ui-loader/ui-loader.component.ngfactory";
import * as i14 from "./modules/core/ui-loader/components/ui-loader/ui-loader.component";
import * as i15 from "./modules/core/ui-loader/services/ui-loader.service";
import * as i16 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.AuthService, i5.UsersService, i6.OlympiadsService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-toast", [], null, null, null, i7.View_ToastComponent_0, i7.RenderType_ToastComponent)), i1.ɵdid(4, 245760, null, 0, i8.ToastComponent, [i9.ToastService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "margin_top_57"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(9, 245760, null, 0, i12.FooterComponent, [i6.OlympiadsService, i10.Router, i4.AuthService], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-ui-loader", [], null, null, null, i13.View_UiLoaderComponent_0, i13.RenderType_UiLoaderComponent)), i1.ɵdid(11, 245760, null, 0, i14.UiLoaderComponent, [i15.UiLoaderService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); _ck(_v, 11, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i16.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i16.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
