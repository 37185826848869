<footer class="footer">
    <section class="container-fluid">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="footer_contacts" *ngIf="isAuth && !isMainPage">
                <a *ngIf="currentOlympiad?.contacts.website" [href]="currentOlympiad?.contacts.website" target="_blank" class="footer_phone fx v-center" >

                    <div>{{currentOlympiad?.contacts.website}}</div>
                </a>
                <a href="tel:{{currentOlympiad?.contacts.phone}}" class="footer_phone fx v-center" *ngIf="currentOlympiad?.contacts.phone">
                    <div class="ic_phone footer_phone_icon"></div>
                    <div class="footer_phone_num">{{currentOlympiad?.contacts.phone}}</div>
                </a>
                <a href="mailto:{{currentOlympiad?.contacts.email}}" class="footer_email fx v-center"
                   *ngIf="currentOlympiad?.contacts.email">
                    <div class="ic_email footer_email_icon"></div>
                    <div class="footer_email_email">{{currentOlympiad?.contacts.email}}</div>
                </a>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="footer_social_fb">
                <div class="footer_fb">
                    <!--<app-feadback [currentOlympiad]="currentOlympiad"></app-feadback>-->
                    <div class="footer_nested_btn" *ngIf="currentOlympiad?.contacts.find_team">
                        <a [href]="currentOlympiad?.contacts.find_team"
                           class="btn btn_md btn_blue_white btn_telegram_bot" target="_blank">
                            {{'COMMON.FIND_TEAM' | translate}}
                        </a>
                    </div>
                </div>
                <div class="footer_social" *ngIf="!isMainPage">
                    <ul class="footer_social_list">
                        <li class="footer_social_item" *ngIf="currentOlympiad?.contacts.vk">
                            <a [href]="currentOlympiad?.contacts.vk" class="ic_icon_vk" target="_blank"></a>
                        </li>
<!--                        <li class="footer_social_item" *ngIf="currentOlympiad?.contacts.website">
                            <a [href]="currentOlympiad?.contacts.website" target="_blank">{{currentOlympiad?.contacts.website}}</a>
                        </li>-->
                        <li class="footer_social_item" *ngIf="currentOlympiad?.contacts.telegram">
                            <a [href]="currentOlympiad?.contacts.telegram" class="ic_telegram" target="_blank"></a>
                        </li>
                        <li class="footer_social_item" *ngIf="currentOlympiad?.contacts.fb">
                          <a [href]="currentOlympiad?.contacts.fb" class="ic_icon_fb" target="_blank"></a>
                        </li>
                      <li class="footer_social_item" *ngIf="currentOlympiad?.contacts.instagram">
                        <a [href]="currentOlympiad?.contacts.instagram" class="ic_instagram" target="_blank"></a>
                      </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</footer>
