import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class RightPanelComponent {
    constructor(usersService) {
        this.usersService = usersService;
        this.slideOut = false;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentUser = yield this.usersService.getCurrentUser();
        });
    }
    tooglePanel() {
        this.slideOut = !this.slideOut;
    }
}
