import * as tslib_1 from "tslib";
import * as download from 'downloadjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth/auth.service";
export class ReportsService {
    constructor(http, authService) {
        this.http = http;
        this.authService = authService;
        this.BASE_URL = '/olympic/reports';
    }
    getCommonReport(olympiadId) {
        return this.http.get(`${this.BASE_URL}/common/${olympiadId}`).toPromise();
    }
    downloadUsersReport(olympiad) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.getReportFile('users', `${olympiad.name}. Список участников ${new Date().getDate()}.${new Date().getMonth() + 1}.xlsx`, olympiad._id);
        });
    }
    downloadInitialUsersReport(olympiad) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.getReportFile('initial_users', `${olympiad.name}. Список участников (неполные) ${new Date().getDate()}.${new Date().getMonth() + 1}.xlsx`, olympiad._id);
        });
    }
    getReportFile(reportUrl, fileName, olympiadId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((res) => {
                const x = new XMLHttpRequest();
                x.open('GET', `${this.BASE_URL}/${reportUrl}/${olympiadId}`, true);
                x.responseType = 'blob';
                x.setRequestHeader(this.authService.AUTH_TOKEN_KEY, this.authService.getToken());
                // TODO 'olympiad-id' to const
                x.setRequestHeader('olympiad-id', olympiadId);
                x.onload = (e) => {
                    // @ts-ignore
                    download(e.target.response, fileName);
                    res();
                };
                x.send();
            });
        });
    }
    initiateSheetsExport(olympiad) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(`${this.BASE_URL}/sheets/${olympiad._id}`).toPromise();
        });
    }
}
ReportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportsService_Factory() { return new ReportsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: ReportsService, providedIn: "root" });
