import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RightPanelComponent } from './components/right-panel/right-panel.component';
import { SingleSelectAutocompleteComponent } from './components/single-select-autocomplete/single-select-autocomplete.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { TabDocsComponent } from '@shared/components/tab-docs/tab-docs.component';
import { TabFaqComponent } from './components/tab-faq/tab-faq.component';
import { FeadbackComponent } from './components/feadback/feadback.component';
import { UserSelectAutocompleteComponent } from './components/user-select-autocomplete/user-select-autocomplete.component';
import { VkCitySelectComponent } from '@shared/components/vk-city-select/vk-city-select.component';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { FilterPipe } from '@shared/pipes/filter.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { MultiSelectAutocompleteComponent } from '@shared/components/multi-select-autocomplete/multi-select-autocomplete.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule
} from '@angular/material/core';
import { CustomDateAdapter } from '@shared/components/datepicker/custom-date-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {VkUniSchoolsSelectComponent} from '@shared/components/vk-uni-schools-select/vk-uni-schools-select.component';
import {UserSearchSelectComponent} from '@shared/components/user-search-select/user-search-select.component';
import { CircleLoaderComponent } from './components/circle-loader/circle-loader.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VkFacultySelectComponent } from '@shared/components/vk-faculty-select/vk-faculty-select.component';
import {TeamFriendsSelectComponent} from '@shared/components/team-friends-select/team-friends-select.component';


const declarations = [
  RightPanelComponent,
  SingleSelectAutocompleteComponent,
  MultiSelectAutocompleteComponent,
  RadioButtonComponent,
  PopUpComponent,
  TabDocsComponent,
  TabFaqComponent,
  FeadbackComponent,
  UserSelectAutocompleteComponent,
  VkCitySelectComponent,
  VkUniSchoolsSelectComponent,
  VkFacultySelectComponent,
  CheckboxComponent,
  FilterPipe,
  DatepickerComponent,
  UserSearchSelectComponent,
  TeamFriendsSelectComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ScrollingModule,
    MatMenuModule,
    MatDialogModule,
    TranslateModule,
    NgxMaskModule.forRoot(),
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule
  ],
  declarations: [...declarations, CircleLoaderComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    // { provide: MAT_NATIVE_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    // { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: DateAdapter, useClass: CustomDateAdapter},
  ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,
        MatMenuModule,
        MatDialogModule,
        TranslateModule,
        RouterModule,
        ...declarations,
        CircleLoaderComponent,
        MatTooltipModule
    ]
})
export class SharedModule { }
