import { Component, OnInit } from '@angular/core';
import { OlympiadsService } from '@backend/olympiads/olympiads.service';
import { Olympiad } from '@backend/olympiads/olympiad.interface';
import { filter as _filter, find as _find } from 'lodash/fp';
import { UsersService } from '@backend/users/users.service';
import { OlympiadUserData } from '@backend/users/user.interface';

@Component({
  selector: 'app-olympiad-selector',
  templateUrl: './olympiad-selector.component.html',
  styleUrls: ['./olympiad-selector.component.scss']
})
export class OlympiadSelectorComponent implements OnInit {

  olympiads: Olympiad[];

  constructor(public olympiadService: OlympiadsService, private usersService: UsersService) {
  }

  ngOnInit() {
    this.getOlympiads();
  }

  async getOlympiads() {
    const currentUser = await this.usersService.getCurrentUser();
    const olympiads = await this.olympiadService.getOlympiads();
    this.olympiads = _filter<Olympiad>((olympiad) => {
      return Boolean(_find<OlympiadUserData>({olympiad: olympiad._id})(currentUser.olympiads));
    })(olympiads);
  }

  selectOlympiad(olympiad: Olympiad) {
    this.olympiadService.selectOlympiad(olympiad);
    window.location.reload();
  }
}
