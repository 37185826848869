import { Component, OnDestroy, OnInit } from '@angular/core';
import { OlympiadsService } from '@backend/olympiads/olympiads.service';
import { Olympiad } from '@backend/olympiads/olympiad.interface';
import { Subscription } from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '@backend/auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  currentOlympiad: Olympiad;
  olympiadSubscription: Subscription;
  isMainPage: boolean;
  isAuth: boolean;
  constructor(private olympiadService: OlympiadsService, private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.isAuth = this.authService.hasSession();
    this.olympiadSubscription = this.olympiadService.$selectedOlympiadId.subscribe((value) => {

      this.currentOlympiad = this.olympiadService.selectedOlympiad;
      this.isAuth = this.authService.hasSession();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const pathname = window.location.pathname;
        this.isMainPage = pathname.indexOf('main') > -1 || pathname === '/';
      }
    });
  }

  ngOnDestroy() {
    if (this.olympiadSubscription) {
      this.olympiadSubscription.unsubscribe();
    }
  }

}
