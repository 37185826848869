<span [matMenuTriggerFor]="menu" class="olympiad-title">{{olympiadService?.selectedOlympiad?.name}}</span>
<mat-menu #menu [overlapTrigger]="false" [xPosition]="'after'" [yPosition]="'below'">
    <div *ngFor="let olympiad of olympiads" mat-menu-item class="olympiad-item"
         [class.selected]="olympiad._id === olympiadService.selectedOlympiadId"
         (click)="selectOlympiad(olympiad)">
        <div class="fx column">
            <span>{{olympiad.name}}</span>
        </div>
    </div>
</mat-menu>
