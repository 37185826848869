import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonReport } from '@backend/reports/common-report.interface';

import * as download from 'downloadjs';
import { AuthService } from '@backend/auth/auth.service';
import { OlympiadsService } from '@backend/olympiads/olympiads.service';
import { Olympiad } from '@backend/olympiads/olympiad.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  BASE_URL = '/olympic/reports';

  constructor(private http: HttpClient,
              private authService: AuthService) {
  }

  getCommonReport(olympiadId: string) {
    return this.http.get<CommonReport>(`${this.BASE_URL}/common/${olympiadId}`).toPromise();
  }

  async downloadUsersReport(olympiad: Olympiad): Promise<any> {
    return this.getReportFile('users',
      `${olympiad.name}. Список участников ${new Date().getDate()}.${new Date().getMonth() + 1}.xlsx`, olympiad._id);
  }

  async downloadInitialUsersReport(olympiad: Olympiad): Promise<any> {
    return this.getReportFile('initial_users',
      `${olympiad.name}. Список участников (неполные) ${new Date().getDate()}.${new Date().getMonth() + 1}.xlsx`, olympiad._id);
  }

  async getReportFile(reportUrl: string, fileName: string, olympiadId: string) {
    return new Promise((res) => {
      const x = new XMLHttpRequest();
      x.open('GET', `${this.BASE_URL}/${reportUrl}/${olympiadId}`, true);
      x.responseType = 'blob';
      x.setRequestHeader(this.authService.AUTH_TOKEN_KEY, this.authService.getToken());
      // TODO 'olympiad-id' to const
      x.setRequestHeader('olympiad-id', olympiadId);
      x.onload = (e) => {
        // @ts-ignore
        download(e.target.response, fileName);
        res();
      };
      x.send();
    });
  }

  async initiateSheetsExport(olympiad: Olympiad) {
    return this.http.get<CommonReport>(`${this.BASE_URL}/sheets/${olympiad._id}`).toPromise();
  }
}
