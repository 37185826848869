import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class UiLoaderService {
    constructor() {
        this.loaderIsVisible = false;
        this.loadingStatus$ = new BehaviorSubject(false);
    }
    /**
     * Получить статус загрузки
     */
    get loadingStatus() {
        return this.loadingStatus$.asObservable();
    }
    /**
     * Показать лоадер
     */
    show() {
        this.loading = true;
    }
    /**
     * Скрыть лоадер
     */
    hide() {
        this.loading = false;
    }
    /**
     * Установить видимость лоадера
     */
    set loading(value) {
        this.loaderIsVisible = value;
        this.loadingStatus$.next(value);
    }
}
UiLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiLoaderService_Factory() { return new UiLoaderService(); }, token: UiLoaderService, providedIn: "root" });
