import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '@backend/auth/auth.service';
import { Olympiad } from '@backend/olympiads/olympiad.interface';

import { environment } from '@env/environment';
import {YaMetrikaService} from '@core/metrika/ya-metrika.service';
// import {Metrika} from 'ng-yandex-metrika';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {

  @Output() closeModal = new EventEmitter<any>();
  @Input() olympiad: Olympiad;

  constructor(private authService: AuthService, private metrika: YaMetrikaService) { }

  ngOnInit() {
    if (environment.production && this.olympiad.contacts.metrika && this.olympiad.contacts.metrika.goals.participate) {
      this.metrika.reachGoal(this.olympiad, 'olympreg_participate');
    }
  }

  closePopUp($event: any) {
    this.closeModal.emit($event);
  }

  signIn() {
    this.authService.authorize();
  }

  signUp() {
    if (this.olympiad.contacts.sign_up_url) {
      window.open(this.olympiad.contacts.sign_up_url, '_blank');
    } else {
      this.authService.registration();
    }
  }
}
