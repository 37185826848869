import { Component, OnInit } from '@angular/core';
import { UsersService } from '@backend/users/users.service';
import { User } from '@backend/users/user.interface';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss']
})
export class RightPanelComponent implements OnInit {

  public slideOut = false;
  currentUser: User;

  constructor(private usersService: UsersService) {
  }

  async ngOnInit() {
    this.currentUser = await this.usersService.getCurrentUser();
  }

  tooglePanel() {
    this.slideOut = !this.slideOut;
  }
}
