import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@backend/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {YaMetrikaService} from "@core/metrika/ya-metrika.service";
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class AppLoadService {
  private router: Router;

  constructor(private authService: AuthService, private injector: Injector, private translate: TranslateService, private metrika: YaMetrikaService) {
  }

  async initApp(): Promise<any> {
    console.log('App initializing...');
    await this.translate.setDefaultLang('ru');
    await this.translate.use('ru');
    await this.authService.initSession();

    if (environment.production) {
      this.metrika.init();
    }
  }
}
