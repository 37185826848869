import * as tslib_1 from "tslib";
import { HttpParams } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { find as _find } from 'lodash/fp';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../metrika/ya-metrika.service";
export class OlympiadsService {
    constructor(http, metrika) {
        this.http = http;
        this.metrika = metrika;
        this.BASE_URL = '/olympic/olympiads';
        this.SELECTED_OLYMPIAD_KEY = 'OLYMPIC-Selected-Olympiad';
        this.$selectedOlympiadId = new ReplaySubject();
        // TODO if null set as first olympiads[0] id
        this.selectedOlympiadId = '';
        this.$selectedOlympiadId.subscribe((value) => {
            this.selectedOlympiadId = value;
            this.selectedOlympiad = _find({ _id: value })(this.olympiads);
        });
    }
    selectOlympiad(olympiad) {
        this.selectedOlympiad = olympiad || null;
        localStorage.setItem(this.SELECTED_OLYMPIAD_KEY, olympiad ? olympiad._id : null);
        this.$selectedOlympiadId.next(olympiad ? olympiad._id : null);
    }
    getOlympiads(params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const httpParams = new HttpParams({ fromObject: params });
            if (!this.olympiads || params) {
                this.olympiads = yield this.http.get(`${this.BASE_URL}`, { params: httpParams }).toPromise();
                this.metrika.initOlympiads(this.olympiads);
                // this.olympiads = withArchived ? olympiads : _reject<Olympiad>({archived: true})(olympiads);
            }
            return this.olympiads;
        });
    }
    getOlympiadById(id) {
        return this.http.get(`${this.BASE_URL}/${id}`).toPromise();
    }
    uploadFirstStepUsersData(olympiadId, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const formData = this.getFormData(file);
            return this.http.post(`${this.BASE_URL}/${olympiadId}/first-step`, formData).toPromise();
        });
    }
    uploadSecondStepUsersData(olympiadId, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const formData = this.getFormData(file);
            return this.http.post(`${this.BASE_URL}/${olympiadId}/second-step`, formData).toPromise();
        });
    }
    uploadStageResultUsersData(olympiadId, stageOrder, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const formData = this.getFormData(file);
            return this.http.post(`${this.BASE_URL}/${olympiadId}/results/${stageOrder}`, formData).toPromise();
        });
    }
    uploadRemovedUsersData(olympiadId, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const formData = this.getFormData(file);
            return this.http.post(`${this.BASE_URL}/${olympiadId}/removed-users`, formData).toPromise();
        });
    }
    updateFAQ(olympiadId, faq) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.put(`${this.BASE_URL}/${olympiadId}/faq`, faq).toPromise();
        });
    }
    updatePages(olympiadId, type, collection) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.put(`${this.BASE_URL}/${olympiadId}/data?type=${type}`, collection).toPromise();
        });
    }
    getFormData(file) {
        const execFile = file;
        // поддерживаемые форматы файлов
        const fileType = /(\.csv)$/i;
        if (!fileType.exec(execFile.name)) {
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        return formData;
    }
    getDefaultOlympiadId() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(`${this.BASE_URL}/default`).toPromise();
        });
    }
}
OlympiadsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OlympiadsService_Factory() { return new OlympiadsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.YaMetrikaService)); }, token: OlympiadsService, providedIn: "root" });
