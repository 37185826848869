import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserAgreement } from '@backend/user-agreements/user-agreement.interface';

@Injectable({
  providedIn: 'root'
})
export class UserAgreementsService {

  private readonly BASE_URL = '/olympic/user-agreement';

  constructor(private http: HttpClient) {
  }

  getUserAgreements(params?: { user_ids?: string, olympiad_ids?: string }): Promise<UserAgreement[]> {
    const httpParams = new HttpParams({ fromObject: params as any });

    return this.http.get<UserAgreement[]>(`${this.BASE_URL}`, { params: httpParams }).toPromise();
  }

  createUserAgreement(userAgreementData: UserAgreement) {
    return this.http.post<UserAgreement>(`${this.BASE_URL}`, userAgreementData).toPromise();
  }

  updateUserAgreement(id: string, userAgreementData: UserAgreement) {
    return this.http.put<UserAgreement>(`${this.BASE_URL}/${id}`, userAgreementData).toPromise();
  }
}
