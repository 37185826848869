import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserData } from '@backend/users/user.interface';
import { forEach as _forEach } from 'lodash/fp';
import { map as _map } from 'lodash/fp';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth/auth.service";
export class UsersService {
    constructor(http, authService) {
        this.http = http;
        this.authService = authService;
        this.BASE_URL = '/olympic/users';
        this.authService.session$.subscribe((session) => {
            this.dropCurrentUser();
        });
    }
    getUsers(params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const httpParams = new HttpParams({ fromObject: params });
            const usersCompact = yield this.http.get(`${this.BASE_URL}`, { params: httpParams }).toPromise();
            const users = _map((userCompact) => new UserData(userCompact))(usersCompact);
            _forEach((user) => {
                this.fixUserNameData(user);
            })(users);
            return users;
        });
    }
    searchUsers(params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const httpParams = new HttpParams({ fromObject: params });
            const result = yield this.http.get(`${this.BASE_URL}`, {
                params: httpParams,
                observe: 'response'
            }).toPromise();
            const users = _map((userCompact) => new UserData(userCompact))(result.body);
            _forEach((user) => {
                this.fixUserNameData(user);
            })(users);
            return {
                totalPages: result.headers.get('x-pagination-total-pages'),
                users
            };
        });
    }
    // getUserById(id: string) {
    //   return this.http.get<User>(`${this.BASE_URL}/${id}`).toPromise();
    // }
    getCurrentUser(forceRequest = false, includeRegId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.currentUser || forceRequest) {
                if (!this.currentUserPromise) {
                    try {
                        let params = null;
                        if (includeRegId) {
                            params = new HttpParams({ fromObject: { with_reg_id: includeRegId } });
                        }
                        const compactUserData = yield (this.currentUserPromise = this.http
                            .get(`${this.BASE_URL}/${this.authService.session._id}`, { params }).toPromise());
                        this.currentUser = new UserData(compactUserData);
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                yield this.currentUserPromise;
                this.currentUserPromise = null;
                this.fixUserNameData(this.currentUser);
            }
            return this.currentUser;
        });
    }
    fixUserNameData(user) {
        const commonname = user.commonname || '';
        const cleanFio = commonname.replace(`(${user.email})`, '');
        const [familyName, firstName, secondName] = cleanFio.replace(/\s+/g, ' ').trim().split(' ');
        user.commonname = cleanFio;
        user.given_name = user.given_name || firstName;
        user.family_name = user.family_name || familyName;
        user.middlename = user.middlename || secondName;
        user.fullName = [
            user.family_name,
            user.given_name,
            user.middlename
        ].join(' ');
    }
    dropCurrentUser() {
        this.currentUser = null;
    }
    updateUser(userId, userData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.http.put(`${this.BASE_URL}/${userId}`, UserData.compact(userData)).toPromise();
            this.currentUser = userData;
        });
    }
    setUserRegId(userId, olympiadId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(`${this.BASE_URL}/${userId}/reg_id`, {
                olympiad_id: olympiadId,
                user_id: userId
            }).toPromise();
        });
    }
}
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: UsersService, providedIn: "root" });
