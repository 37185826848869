import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { some as _some } from 'lodash/fp';
import {UiLoaderService} from '@core/ui-loader/services/ui-loader.service';

@Injectable({
    providedIn: 'root'
})
export class UiLoaderInterceptorService implements HttpInterceptor {

    /**
     * Количество активных запросов
     */
    private activeRequestCount = 0;

    /**
     * Список url, для которых не отображать лоудер
     * Пример: '/subjects'
     */
    private skippedUrls: Array<string> = [
      '/vk/cities',
      '/reports/sheets/',
    ];

    /**
     * http методы, для которых не отображать лоудер
     * Пример: 'GET'
     */
    private skippedMethods: Array<string> = [];

    constructor(private uiLoader: UiLoaderService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.interceptRequest(request);

        return next.handle(request).pipe(
            tap(
                () => {},
                (event) => {
                    if (event instanceof HttpErrorResponse && event.status >= 500 && !this.shouldBypassUrl(request.url)) {
                        console.log(event.error && event.error.errorText || event.statusText);
                    }
                }),
            finalize(() => {
                this.interceptResponse();
            })
        );
    }


    /**
     * Перехватить запрос
     * В параметр запроса может попасть не только HttpRequest,
     * но также синтетический объект, переданный в перехватчике запроса restangular'a.
     */
    interceptRequest(request: any) {
        const shouldBypass = this.shouldBypass(request);

        if (!shouldBypass) {
            if (this.activeRequestCount === 0) {
                this.uiLoader.show();
            }

            this.activeRequestCount++;
        }
    }

    /**
     * Перехватить ответ
     */
    interceptResponse() {
        this.activeRequestCount--;
        if (this.activeRequestCount < 0) {
            this.activeRequestCount = 0;
        }

        if (this.activeRequestCount === 0) {
            this.uiLoader.hide();
        }
    }

    /**
     * Проверить, следует ли пропустить запрос, переданный в параметр
     */
    private shouldBypass(request: HttpRequest<any>): boolean {
        return this.shouldBypassMethod(request.method)
            || this.shouldBypassUrl(request.url);
    }

    /**
     * Проверить, следует пропустить запрос по заданному url
     */
    private shouldBypassUrl(url: string): boolean {
        return _some((skipped: string) => {
            return url.indexOf(skipped) !== -1;
        })(this.skippedUrls);
    }

    /**
     * Проверить, следует ли пропустить запрос с заданным http-методом
     */
    private shouldBypassMethod(method: string) {
        return _some((skipped: string) => {
            return new RegExp(skipped, 'i').test(method);
        })(this.skippedMethods);
    }
}
