import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiLoaderComponent } from '@core/ui-loader/components/ui-loader/ui-loader.component';
import { UiLoaderService } from '@core/ui-loader/services/ui-loader.service';
import { UiLoaderInterceptorService } from '@core/ui-loader/services/ui-loader-interceptor.service';


@NgModule({
  declarations: [
    UiLoaderComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    UiLoaderService,
    UiLoaderInterceptorService
  ],
  exports: [
    UiLoaderComponent
  ]
})
export class UiLoaderModule { }
