import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwtDecode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '@env/environment';
import { ReplaySubject } from 'rxjs';
import { UsersService } from '@backend/users/users.service';
import {OlympiadsService} from "@backend/olympiads/olympiads.service";
// import { UserSettingsService } from '@backend/user-settings/user-settings.service';
// import { UserProfileService } from '@backend/user-profile/user-profile.service';

interface Session {
  _id: string;
  commonname: string;
  given_name: string;
  email: string;
  exp: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  AUTH_TOKEN_KEY = 'OLYMPIC-Auth-Token';
  SELECTED_OLYMPIAD_KEY = 'OLYMPIC-Selected-Olympiad';
  AUTHORIZE_URL = `${environment.AUTHORIZE}`; // /authorize
  LOGOUT_URL = `${environment.LOGOUT}`; // `${environment.ADFS}/logout`
  REGISTRATION_URL = `${environment.REGISTRATION}`; // 'https://lk.hse.ru/registration';
  RESTORE_URL = `${environment.AUTH_POINT}/password_recovery`; // 'https://lk.hse.ru/password_recovery';
  BASE_URL = '/olympic/auth';


  session: Session = null;
  session$: ReplaySubject<Session> = new ReplaySubject<Session>();
  nonceId = null;
  stateId = null;

  constructor(private http: HttpClient,
              private olympiadService: OlympiadsService
              /*      private userSettings: UserSettingsService,
                    private userProfile: UserProfileService*/) {
  }

  authorize() {
    window.open(this.getAuthUrl());
  }

  getAuthUrl() {
    this.nonceId = uuidv4();
    this.stateId = uuidv4();
    // window.open(`${this.AUTHORIZE_URL}?response_type=code&client_id=${this.CLIENT_ID}&redirect_uri=http://${window.location.host}/callback`);
    const params = {
      response_type: 'id_token token',
      scope: 'openid profile',
      state: this.stateId,
      nonce: this.nonceId,
      client_id: environment.CLIENT_ID,
      redirect_uri: environment.redirectURI
      // response_mode=form_post
      // &response_type=code
    };

    return `${this.AUTHORIZE_URL}${this.makeParams(params)}`;
  }

  registration() {

    const params = {
      type: 'olympreg',
      returnurl: encodeURIComponent(this.getAuthUrl())
    };

/*    console.log(this.getAuthUrl());
    console.log(`${this.REGISTRATION_URL}${this.makeParams(params)}`);*/

    window.open(`${this.REGISTRATION_URL}${this.makeParams(params)}`);
  }

  restore() {
    const params = {
      // TODO redirect callback?
    };
    window.open(`${this.RESTORE_URL}${this.makeParams(params)}`);
  }

  async authByToken(accessToken: string) {
    const response: any = await this.http.post(`${this.BASE_URL}/token`, { token: accessToken }).toPromise();
    if (response.token) {
      // localStorage.setItem('ADFS-access-token', String(accessToken));
      localStorage.setItem(this.AUTH_TOKEN_KEY, String(response.token));
      // console.log(this.session);
    }

    await this.initSession();

    return this.session;
  }

  async initSession() {
    const token = this.getToken();
    if (token) {
      this.session = jwtDecode(token) as Session;
      this.session$.next(this.session);

      let selectedOlympiadId = localStorage.getItem(this.SELECTED_OLYMPIAD_KEY);

      if (!selectedOlympiadId || selectedOlympiadId === 'null') {
        const idData = await this.olympiadService.getDefaultOlympiadId();
        localStorage.setItem(this.SELECTED_OLYMPIAD_KEY, idData.id);
        selectedOlympiadId = idData.id;
      }

      this.olympiadService.selectedOlympiadId = selectedOlympiadId;
      await this.olympiadService.getOlympiads();
      this.olympiadService.$selectedOlympiadId.next(this.olympiadService.selectedOlympiadId);
      // this.userSettings.getUserSettings();
      // this.userProfile.getUserInfo();
    }
  }

  getToken() {
    return localStorage.getItem(this.AUTH_TOKEN_KEY);
  }

  isAuth() {
    const authToken = this.getToken();
    // const authToken = true;

    return Boolean(authToken);

    // if (authToken) {
    //     try {
    //         await this.http.get(`${this.BASE_URL}/session`).toPromise();
    //
    //         return true;
    //     } catch (err) {
    //         console.log(err);
    //         this.cookies.delete(this.AUTH_TOKEN);
    //     }
    // }
    //
    // return false;
  }

  hasSession(): boolean {
    return Boolean(this.session);
  }

  dropSession() {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
    this.session = null;
    this.session$.next(this.session);
  }

  logout(withRedirect = true) {
    const params = {
      // id_token_hint:  localStorage.getItem('ADFS-access-token'),
      // post_logout_redirect_uri: window.location.href
    };

    this.dropSession();

    window.open(`${this.LOGOUT_URL}${this.makeParams(params)}`, '_blank');

    if (withRedirect) {
      window.open('/', '_self');
    }
  }

  private makeParams(params) {
    let result = '';
    if (params) {
      const data = [];
      for (const param in params) {
        if (params.hasOwnProperty(param)) {
          data.push(`${param}=${params[param]}`);
        }
      }
      result = `?${data.join('&')}`;
    }

    return result;
  }
}
