import {Injectable} from '@angular/core';
import {Olympiad} from '@backend/olympiads/olympiad.interface';
import {
  cloneDeep as _cloneDeep,
  filter as _filter,
  find as _find,
  forEach as _forEach,
  lowerCase as _lowerCase,
  toString as _toString,
  map as _map
} from 'lodash/fp';

/* tslint:disable */
@Injectable({
  providedIn: 'root'
})
export class YaMetrikaService {

  isInited = false;
  ym: any;
  init() {
    if (this.isInited) {
      return
    }
    // @ts-ignore
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      // @ts-ignore
      m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    this.ym = window['ym'];
    this.isInited = true;
  }

  initOlympiad(metrikaId: number) {
    if (!this.isInited) {
      return
    }
    this.ym(metrikaId, "init", {});
  }

  initOlympiads(olympiads: Olympiad[]) {
    if (!this.isInited) {
      return
    }

    _forEach<Olympiad>((olympiad)=> {
      if (olympiad.contacts.metrika) {
        console.log(olympiad.short_name)
        this.initOlympiad(olympiad.contacts.metrika.id)
      }
    })(olympiads);
  }

  reachGoal(olympiad: Olympiad, goal: string) {
    if (!this.isInited) {
      return
    }

    this.ym(olympiad.contacts.metrika.id,'reachGoal', goal);
  }
}
