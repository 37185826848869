import { HttpErrorResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { some as _some } from 'lodash/fp';
import * as i0 from "@angular/core";
import * as i1 from "./ui-loader.service";
export class UiLoaderInterceptorService {
    constructor(uiLoader) {
        this.uiLoader = uiLoader;
        /**
         * Количество активных запросов
         */
        this.activeRequestCount = 0;
        /**
         * Список url, для которых не отображать лоудер
         * Пример: '/subjects'
         */
        this.skippedUrls = [
            '/vk/cities',
            '/reports/sheets/',
        ];
        /**
         * http методы, для которых не отображать лоудер
         * Пример: 'GET'
         */
        this.skippedMethods = [];
    }
    intercept(request, next) {
        this.interceptRequest(request);
        return next.handle(request).pipe(tap(() => { }, (event) => {
            if (event instanceof HttpErrorResponse && event.status >= 500 && !this.shouldBypassUrl(request.url)) {
                console.log(event.error && event.error.errorText || event.statusText);
            }
        }), finalize(() => {
            this.interceptResponse();
        }));
    }
    /**
     * Перехватить запрос
     * В параметр запроса может попасть не только HttpRequest,
     * но также синтетический объект, переданный в перехватчике запроса restangular'a.
     */
    interceptRequest(request) {
        const shouldBypass = this.shouldBypass(request);
        if (!shouldBypass) {
            if (this.activeRequestCount === 0) {
                this.uiLoader.show();
            }
            this.activeRequestCount++;
        }
    }
    /**
     * Перехватить ответ
     */
    interceptResponse() {
        this.activeRequestCount--;
        if (this.activeRequestCount < 0) {
            this.activeRequestCount = 0;
        }
        if (this.activeRequestCount === 0) {
            this.uiLoader.hide();
        }
    }
    /**
     * Проверить, следует ли пропустить запрос, переданный в параметр
     */
    shouldBypass(request) {
        return this.shouldBypassMethod(request.method)
            || this.shouldBypassUrl(request.url);
    }
    /**
     * Проверить, следует пропустить запрос по заданному url
     */
    shouldBypassUrl(url) {
        return _some((skipped) => {
            return url.indexOf(skipped) !== -1;
        })(this.skippedUrls);
    }
    /**
     * Проверить, следует ли пропустить запрос с заданным http-методом
     */
    shouldBypassMethod(method) {
        return _some((skipped) => {
            return new RegExp(skipped, 'i').test(method);
        })(this.skippedMethods);
    }
}
UiLoaderInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiLoaderInterceptorService_Factory() { return new UiLoaderInterceptorService(i0.ɵɵinject(i1.UiLoaderService)); }, token: UiLoaderInterceptorService, providedIn: "root" });
