import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { ControlValueAccessor } from '@angular/forms';
export class VkUniSchoolsSelectComponent {
    constructor(vkProxyService) {
        this.vkProxyService = vkProxyService;
        this.organizationChanged = new EventEmitter();
        this.searchString$ = new Subject();
        this.schools = [];
        this.universities = [];
        this.organizations = [];
        this.isSearchResultsEmpty = false;
        this.onChange = () => { };
        this.onTouched = () => { };
    }
    get model() {
        return this.selectedOrganization;
    }
    set model(value) {
        this.selectedOrganization = value;
    }
    ngOnChanges(changes) {
    }
    ngOnInit() {
        this.searchString$
            .pipe(debounceTime(1000), filter((q) => q.length >= 3), distinctUntilChanged()).subscribe((searchString) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.universitiesOnly) {
                try {
                    this.schools = searchString
                        ? yield this.vkProxyService.getAllSchools({ city_id: this.cityId, q: searchString, count: 500 })
                        : [];
                }
                catch (err) {
                    this.schools = [];
                    console.error(err);
                }
            }
            else {
                this.schools = [];
            }
            if (!this.schoolsOnly) {
                try {
                    this.universities = searchString
                        ? yield this.vkProxyService.getUniversities({
                            country_id: this.countryId || 1,
                            city_id: this.cityId,
                            q: searchString,
                            count: 500
                        })
                        : [];
                }
                catch (err) {
                    this.universities = [];
                    console.error(err);
                }
            }
            else {
                this.universities = [];
            }
            // @ts-ignore
            this.organizations = this.universities.concat(this.schools);
            this.isSearchResultsEmpty = !this.organizations.length;
        }));
    }
    writeValue(value) {
        this.model = value;
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    searchStringChanged($event) {
        this.searchString$.next($event);
    }
    selectOrganization(organization) {
        this.model = organization.title;
        this.organizationChanged.emit(organization);
    }
    saveSearchString() {
        this.model = this.searchString;
        this.organizationChanged.emit({
            id: null,
            title: this.searchString
        });
    }
    optionListClosed() {
    }
    optionListOpened() {
    }
}
