import { Routes } from '@angular/router';
import { OauthCallbackComponent } from '@backend/oauth-callback/oauth-callback.component';
const ɵ0 = () => import("./modules/main/main.module.ngfactory").then((m) => m.MainModuleNgFactory), ɵ1 = () => import("./modules/olympiads/olympiads.module.ngfactory").then((m) => m.OlympiadsModuleNgFactory), ɵ2 = () => import("./modules/personal-area/personal-area.module.ngfactory").then((m) => m.PersonalAreaModuleNgFactory), ɵ3 = () => import("./modules/reports/reports.module.ngfactory").then((m) => m.ReportsModuleNgFactory), ɵ4 = () => import("./modules/settings/settings.module.ngfactory").then((m) => m.SettingsModuleNgFactory), ɵ5 = () => import("./modules/user-olympiad-approve/user-olympiad-approve.module.ngfactory").then((m) => m.UserOlympiadApproveModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: 'olympiads',
        loadChildren: ɵ1
    },
    {
        path: 'personal',
        loadChildren: ɵ2
    },
    {
        path: 'reports',
        loadChildren: ɵ3
    },
    {
        path: 'settings',
        loadChildren: ɵ4
    },
    {
        path: 'upload-approved-users',
        loadChildren: ɵ5
    },
    {
        path: 'callback',
        component: OauthCallbackComponent
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
