import { NgModule } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { OauthCallbackComponent } from '@backend/oauth-callback/oauth-callback.component';
import { TranslateModule } from '@ngx-translate/core';
import { InterceptorService } from '@backend/interceptor.service';
import { UsersService } from '@backend/users/users.service';
import { VkProxyService } from '@backend/vk-proxy/vk-proxy.service';
import { SupportService } from '@backend/support/support.service';
import { AttachmentsService } from '@backend/attachments/attachments.service';
import { UserAgreementsService } from '@backend/user-agreements/user-agreements.service';
import { ReportsService } from '@backend/reports/reports.service';
import { SettingsService } from '@backend/settings/settings.service';

const declarations = [
  OauthCallbackComponent
];

@NgModule({
  declarations,
  imports: [
    HttpClientModule,
    TranslateModule
  ],
  providers: [
    AuthService,
    InterceptorService,
    UsersService,
    VkProxyService,
    SupportService,
    AttachmentsService,
    UserAgreementsService,
    ReportsService,
    SettingsService
  ],
  exports: declarations
})
export class BackendModule { }
