import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { VkCity } from '@backend/vk-proxy/vk-city.interface';
import { VkRegion } from '@backend/vk-proxy/vk-region.interface';

import { getOr as _getOr } from 'lodash/fp';
import { VkCountry } from '@backend/vk-proxy/vk-country.interface';
import { VkUniversity } from '@backend/vk-proxy/vk-university.interface';
import { VkSchool } from '@backend/vk-proxy/vk-school.interface';
import { VkFaculty } from '@backend/vk-proxy/vk-faculty.interface';
import { VkChair } from '@backend/vk-proxy/vk-chair.interface';

@Injectable({
  providedIn: 'root'
})
export class VkProxyService {
  BASE_URL = '/olympic/vk';

  RETRY_COUNT = 3;
  RETRY_TIMEOUT = 3000;

  reqs = {};

  constructor(private http: HttpClient) {
  }

  getCities(params?: {country_id: number, region_id?: number, need_all?: number, q?: string, offset?: number, count?: number}) {
    return this.getAll<VkCity>('/cities', params);
  }

  getCitiesByIds(params?: {city_ids: string, country_id: number, lang: string}) {
    // @ts-ignore
    return this.http.get<VkCity>(this.BASE_URL + '/cities_by_ids', {params}).toPromise();
  }

  getRegions(params?: {country_id?: number, q?: string, offset?: number, count?: number}) {
    return this.getAll<VkRegion>('/regions', params);
  }

  getCountries(params?: {country_id?: number, q?: string, offset?: number, count?: number, need_all?: boolean}) {
    return this.getAll<VkCountry>('/countries', params);
  }

  getAllSchools(params?: {city_id?: number, q?: string, offset?: number, count?: number}) {
    return this.getAll<VkSchool>('/schools', params);
  }

  getSchools(params?: { city_id?: number, q?: string, offset?: number, count?: number }) {
    const httpParams = new HttpParams({ fromObject: params as any });
    return this.http.get(this.BASE_URL + '/schools', {params: httpParams}).toPromise();
  }

  getUniversities(params?: { country_id?: number, city_id?: number, q?: string, offset?: number, count?: number }) {
    return this.getAll<VkUniversity>('/universities', params);
  }

  getFaculties(params?: { university_id?: number, /*city_id?: number, q?: string,*/ offset?: number, count?: number }) {
    return this.getAllWithRetry<VkFaculty>('/faculties', params);
  }

  getChairs(params?: { faculty_id?: number, offset?: number, count?: number }) {
    return this.getAllWithRetry<VkChair>('/chairs', params);
  }

  async getAllWithRetry<T>(apiUrl: string, params: { [key: string]: any } = {}, reqId?): Promise<T[]> {
    const currentReqId = reqId || new Date();

    let requestCount;
    if (reqId) {
      requestCount = this.reqs[reqId];
    } else {
      this.reqs[currentReqId] = 0;
      requestCount = this.reqs[currentReqId];
    }

    try {
     const res = await this.getAll<T>(apiUrl, params);

     delete this.reqs[currentReqId];

     return res;
    } catch (e) {
      this.reqs[currentReqId] += 1 ;
      if (requestCount < this.RETRY_COUNT) {
        await this.wait(this.RETRY_TIMEOUT);
        return this.getAllWithRetry(apiUrl, params, currentReqId);
      }
    }
  }

  async getAll<T>(apiUrl: string, params: { [key: string]: any } = {}): Promise<T[]> {
    try {
      const limit = 1000;
      let offset = 0;
      let totalCount = null;
      let allItems: T[] = [];
      params.limit = limit;
      params.lang = 'ru';

      while (totalCount === null || offset < totalCount) {
        params.offset = offset;
        const data: any = await this.http.get(this.BASE_URL + apiUrl, {params}).toPromise();

        totalCount = data.count || _getOr(0, 'count')(data.response);
        const items = data.items || _getOr([], 'items')(data.response);
        allItems = allItems.concat(items);
        offset += items.length;

        if (!items.length) {
          return allItems;
        }
      }

      return allItems;
    } catch (e) {
      throw new Error(e);
    }
  }

  async wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
