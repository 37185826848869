import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class HeaderComponent {
    constructor(authService, userService, olympiadService) {
        this.authService = authService;
        this.userService = userService;
        this.olympiadService = olympiadService;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // if (this.authService.hasSession()) {
            //   this.currentUser = await this.userService.getCurrentUser();
            // }
            this.authService.session$.subscribe((session) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.currentUser = session ? yield this.userService.getCurrentUser() : null;
            }));
            // this.signUp();
        });
    }
    signIn() {
        this.authService.authorize();
    }
    signUp() {
        this.authService.registration();
    }
    restorePassword() {
        this.authService.restore();
    }
}
