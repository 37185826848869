import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { environment } from "@env/environment";
import * as i0 from "@angular/core";
import * as i1 from "./modules/core/backend/auth/auth.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./modules/core/metrika/ya-metrika.service";
export class AppLoadService {
    constructor(authService, injector, translate, metrika) {
        this.authService = authService;
        this.injector = injector;
        this.translate = translate;
        this.metrika = metrika;
    }
    initApp() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('App initializing...');
            yield this.translate.setDefaultLang('ru');
            yield this.translate.use('ru');
            yield this.authService.initSession();
            if (environment.production) {
                this.metrika.init();
            }
        });
    }
}
AppLoadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppLoadService_Factory() { return new AppLoadService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.YaMetrikaService)); }, token: AppLoadService, providedIn: "root" });
