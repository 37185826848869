import {
  Component, ElementRef, EventEmitter, forwardRef, Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UsersService } from '@backend/users/users.service';
import { OlympiadUserData, User } from '@backend/users/user.interface';
import { find as _find, reject as _reject, filter as _filter, flow as _flow, concat as _concat } from 'lodash/fp';
import { Subjects } from '@backend/teams/subjects.enum';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-user-search-select',
  templateUrl: './user-search-select.component.html',
  styleUrls: ['./user-search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UserSearchSelectComponent),
    },
  //  {provide: VIRTUAL_SCROLL_STRATEGY, useClass: FixedSizeVirtualScrollStrategy}
  ]
})
export class UserSearchSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() width: string;
  @Input() disabled: boolean;
  @Input() olympiadId;
  @Input() subjectKey: Subjects;
  @Input() unavailableUserIds: string[];
  @Input() freeUsers: User[];
  @Output() userChanged = new EventEmitter<User>();

  @ViewChild('input', { static: true }) input: ElementRef;

  @ViewChild('viewport', null)
  viewport: CdkVirtualScrollViewport;

  searchString$: Subject<string> = new Subject<string>();
  searchString: string;
  selectedUser: User;
  users: User[] = [];
  searchResults: User[] = [];

  isLoading: boolean;

  page = 1;
  perPage = 100;
  totalPages = 10;

  get model() {
    return this.selectedUser;
  }

  set model(value: User) {
    this.selectedUser = value;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private userService: UsersService ) { }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnInit() {
    this.searchString$
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe(async (searchString) => {
        this.users = [];
        this.searchResults = [];
        this.page = 1;
        this.searchString = searchString;
        await this.getSearchPage();
        this.processFreeAndUnavailableUsers();
      });
  }

  async getSearchPage(): Promise<User[]> {
    this.isLoading = true;
    const subjectFilterParam = this.subjectKey ? {subject_key: this.subjectKey} : {};

    const searchResponse = this.searchString
      ? await this.userService.searchUsers({
        without_team: true,
        olympiad_id: this.olympiadId,
        search_string: this.searchString,
        page: this.page,
        per_page: this.perPage,
        ...subjectFilterParam
      })
      : [];

    // @ts-ignore
    this.searchResults = this.searchResults.concat(searchResponse.users);
    // @ts-ignore
    this.totalPages = Number(searchResponse.totalPages);
    this.isLoading = false;

    // @ts-ignore
    return searchResponse.users;
  }

  processFreeAndUnavailableUsers() {
    // console.log('processFreeAndUnavailableUsers', this.unavailableUserIds, this.freeUsers);
    // убираем полоьзователей которые уже выбраны, но не сохранены на бэке
    const filteredUnavalableUsers = _reject<User>((user) => {
      return this.unavailableUserIds.includes(user._id);
    })(this.searchResults);

    // добавляем пользователей, которые уже доступны, но не сохранены на бэке, фильтруем по предмету
    this.users = _flow(
      _filter<User>((user) => {
        const currentOlympiadUserData = _find<OlympiadUserData>({olympiad: this.olympiadId})(user.olympiads);
        const userTextData = `${user.family_name} ${user.given_name || ''} ${user.middlename || ''} ${user.email}`;

        return (!this.subjectKey || currentOlympiadUserData.subject_key === this.subjectKey)
          && userTextData.indexOf(this.searchString) > -1;
      }),
      _concat(filteredUnavalableUsers)
    )(this.freeUsers);
  }

  writeValue(value) {
    this.model = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  searchStringChanged($event) {
    this.searchString$.next($event);
  }

  selectUser(user: User) {
    this.model = user;
    this.userChanged.emit(user);

    // this.users = [];
  }

  optionListClosed() {
    // this.users = [];
  }

  optionListOpened() {
    this.processFreeAndUnavailableUsers();
  }

  async checkScrolled($event) {
    if (this.isLoading) {
      return;
    }

    const range = this.viewport.getRenderedRange();
    if (range.end > 0 && (range.end === this.users.length) && this.page < this.totalPages) {
      this.page += 1;
      const page = await this.getSearchPage();
      this.users = this.users.concat(page);
    }
  }
}
