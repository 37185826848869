import * as tslib_1 from "tslib";
import jwtDecode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '@env/environment';
import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../olympiads/olympiads.service";
export class AuthService {
    constructor(http, olympiadService
    /*      private userSettings: UserSettingsService,
          private userProfile: UserProfileService*/ ) {
        this.http = http;
        this.olympiadService = olympiadService;
        this.AUTH_TOKEN_KEY = 'OLYMPIC-Auth-Token';
        this.SELECTED_OLYMPIAD_KEY = 'OLYMPIC-Selected-Olympiad';
        this.AUTHORIZE_URL = `${environment.AUTHORIZE}`; // /authorize
        this.LOGOUT_URL = `${environment.LOGOUT}`; // `${environment.ADFS}/logout`
        this.REGISTRATION_URL = `${environment.REGISTRATION}`; // 'https://lk.hse.ru/registration';
        this.RESTORE_URL = `${environment.AUTH_POINT}/password_recovery`; // 'https://lk.hse.ru/password_recovery';
        this.BASE_URL = '/olympic/auth';
        this.session = null;
        this.session$ = new ReplaySubject();
        this.nonceId = null;
        this.stateId = null;
    }
    authorize() {
        window.open(this.getAuthUrl());
    }
    getAuthUrl() {
        this.nonceId = uuidv4();
        this.stateId = uuidv4();
        // window.open(`${this.AUTHORIZE_URL}?response_type=code&client_id=${this.CLIENT_ID}&redirect_uri=http://${window.location.host}/callback`);
        const params = {
            response_type: 'id_token token',
            scope: 'openid profile',
            state: this.stateId,
            nonce: this.nonceId,
            client_id: environment.CLIENT_ID,
            redirect_uri: environment.redirectURI
            // response_mode=form_post
            // &response_type=code
        };
        return `${this.AUTHORIZE_URL}${this.makeParams(params)}`;
    }
    registration() {
        const params = {
            type: 'olympreg',
            returnurl: encodeURIComponent(this.getAuthUrl())
        };
        /*    console.log(this.getAuthUrl());
            console.log(`${this.REGISTRATION_URL}${this.makeParams(params)}`);*/
        window.open(`${this.REGISTRATION_URL}${this.makeParams(params)}`);
    }
    restore() {
        const params = {
        // TODO redirect callback?
        };
        window.open(`${this.RESTORE_URL}${this.makeParams(params)}`);
    }
    authByToken(accessToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.http.post(`${this.BASE_URL}/token`, { token: accessToken }).toPromise();
            if (response.token) {
                // localStorage.setItem('ADFS-access-token', String(accessToken));
                localStorage.setItem(this.AUTH_TOKEN_KEY, String(response.token));
                // console.log(this.session);
            }
            yield this.initSession();
            return this.session;
        });
    }
    initSession() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = this.getToken();
            if (token) {
                this.session = jwtDecode(token);
                this.session$.next(this.session);
                let selectedOlympiadId = localStorage.getItem(this.SELECTED_OLYMPIAD_KEY);
                if (!selectedOlympiadId || selectedOlympiadId === 'null') {
                    const idData = yield this.olympiadService.getDefaultOlympiadId();
                    localStorage.setItem(this.SELECTED_OLYMPIAD_KEY, idData.id);
                    selectedOlympiadId = idData.id;
                }
                this.olympiadService.selectedOlympiadId = selectedOlympiadId;
                yield this.olympiadService.getOlympiads();
                this.olympiadService.$selectedOlympiadId.next(this.olympiadService.selectedOlympiadId);
                // this.userSettings.getUserSettings();
                // this.userProfile.getUserInfo();
            }
        });
    }
    getToken() {
        return localStorage.getItem(this.AUTH_TOKEN_KEY);
    }
    isAuth() {
        const authToken = this.getToken();
        // const authToken = true;
        return Boolean(authToken);
        // if (authToken) {
        //     try {
        //         await this.http.get(`${this.BASE_URL}/session`).toPromise();
        //
        //         return true;
        //     } catch (err) {
        //         console.log(err);
        //         this.cookies.delete(this.AUTH_TOKEN);
        //     }
        // }
        //
        // return false;
    }
    hasSession() {
        return Boolean(this.session);
    }
    dropSession() {
        localStorage.removeItem(this.AUTH_TOKEN_KEY);
        this.session = null;
        this.session$.next(this.session);
    }
    logout(withRedirect = true) {
        const params = {
        // id_token_hint:  localStorage.getItem('ADFS-access-token'),
        // post_logout_redirect_uri: window.location.href
        };
        this.dropSession();
        window.open(`${this.LOGOUT_URL}${this.makeParams(params)}`, '_blank');
        if (withRedirect) {
            window.open('/', '_self');
        }
    }
    makeParams(params) {
        let result = '';
        if (params) {
            const data = [];
            for (const param in params) {
                if (params.hasOwnProperty(param)) {
                    data.push(`${param}=${params[param]}`);
                }
            }
            result = `?${data.join('&')}`;
        }
        return result;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.OlympiadsService)); }, token: AuthService, providedIn: "root" });
