<header class="header">
    <section class="container-fluid">
        <div class="fx v-center space-between">
            <div>
                <div class="fx v-center header_col_height">
                    <a [routerLink]="['/']">
                        <div class="logo"></div>
                    </a>
                    <a *ngIf="authService.hasSession() && currentUser?.teams?.length"
                       [routerLink]="['/', 'personal']"
                       class="default_txt hidden-xs">
                        {{ 'HEADER.PERSONAL_AREA' | translate }}
                    </a>
                </div>
            </div>
            <div>
                <div class="fx v-center h-end header_col_height">
                    <ul class="header_top_menu fx v-center">
<!--                        <li class="header_top_menu_item hidden-xs">
                            <div class="chose_lang fx v-center">
                                <div class="default_txt">
                                    {{ 'HEADER.LANGUAGE.RU' | translate }}
                                </div>
                                <div class="ic_icon_dd_24"></div>
                            </div>
                        </li>-->

<!--                        <li class="header_top_menu_item hidden-xs">
                            <div class="messenges ic_icon_messenges"></div>
                        </li>
                        <li class="header_top_menu_item" >
                            <div class="notify ic_icon_bell"></div>
                        </li>-->
                        <li class="header_top_menu_item" *ngIf="!authService.hasSession()">
                            <div class="fx v-center">
                                <span class="sign_in default_txt clickable" (click)="signIn()">{{'HEADER.SIGN_IN' | translate}}</span>
<!--                                <span class="sign_up default_txt clickable" (click)="signUp()">{{'HEADER.SIGN_UP' | translate}}</span>-->
                                <span class="sign_up default_txt clickable" (click)="restorePassword()">{{'HEADER.RESTORE_PASSWORD' | translate}}</span>
                            </div>
                        </li>
                        <li class="header_top_menu_item" *ngIf="authService.hasSession()">
                            <div class="fx v-center">
                                <div class="fx v-center header_user">
                                  <i class="ic_icon_team"></i>
                                  <div class="fx column">
                                    <span class="user-name">{{currentUser?.family_name}} {{currentUser?.given_name}}</span>
                                    <app-olympiad-selector></app-olympiad-selector>
                                  </div>

              <!--                      <div class="header_user_img">
                                        <img src="assets/images/small.jpg" />
                                    </div>-->
                                </div>
                                <a class="logout ic_exit clickable" (click)="authService.logout()"></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</header>
