import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ControlValueAccessor } from '@angular/forms';
export class VkCitySelectComponent {
    constructor(vkProxyService) {
        this.vkProxyService = vkProxyService;
        this.cityChanged = new EventEmitter();
        this.searchString$ = new Subject();
        this.onChange = () => { };
        this.onTouched = () => { };
    }
    get model() {
        return this.selectedCity;
    }
    set model(value) {
        this.selectedCity = value;
    }
    ngOnChanges(changes) {
    }
    ngOnInit() {
        this.searchString$
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((searchString) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.cities = searchString
                ? yield this.vkProxyService.getCities({ country_id: this.countryId || 1, q: searchString })
                : [];
        }));
    }
    writeValue(value) {
        this.model = value;
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    searchStringChanged($event) {
        this.searchString$.next($event);
    }
    selectCity(city) {
        this.model = city.title;
        this.cityChanged.emit(city);
    }
    optionListClosed() {
    }
    optionListOpened() {
    }
}
