import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Attachment } from '@backend/attachments/attachment.interface';
import { ToastService } from '@core/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  SIZE_LIMIT = 15 * 1024 * 1024;

  private readonly url = '/olympic/attachments';

  constructor(private http: HttpClient, private toastService: ToastService, private translateService: TranslateService) { }

  async create(file: Blob): Promise<Attachment> {

    if (file.size > this.SIZE_LIMIT) {
      this.toastService.sendToast({
        type: 'error',
        text: await this.translateService.get('COMMON.FILE_LIMIT_ALERT').toPromise(),
        width: 400
      });

      return;
    }

    const execFile: any = file;
    // поддерживаемые форматы файлов
    const fileType = /(\.jpg|\.jpeg|\.jpe|\.png|\.svg|\.svgz|\.mp3|\.doc|\.docx|\.pdf)$/i;
    // this.graduationYear.reset();
    if (!fileType.exec(execFile.name)) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<Attachment>(this.url, formData).toPromise();
  }
}
