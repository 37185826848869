import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ToastService} from './toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {

  tText?: string;
  tType?: 'info' | 'success' | 'error' | 'warning';
  tWidth?: number;

  toastSubscribe: Subscription;

  /**
   * Отображение тоаста true/false
   */
  isShow = false;

  constructor(private toastSrv: ToastService) { }

  ngOnInit() {
    this.toastSubscribe = this.toastSrv.getToast().subscribe((data) => {
      this.isShow = true;

      this.tText = data.text;
      this.tType = data.type;
      this.tWidth = data.width;

      setTimeout(() => {
        this.isShow = false;
      }, 3000);
    });
  }

  /**
   * Закрываем тоаст
   */
  close() {
    this.isShow = false;
  }

  ngOnDestroy() {
    this.toastSubscribe.unsubscribe();
  }
}
