import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {User, UserCompact, UserData} from '@backend/users/user.interface';
import { AuthService } from '@backend/auth/auth.service';
import { forEach as _forEach } from 'lodash/fp';
import { map as _map } from 'lodash/fp';
import {Subjects} from "@backend/teams/subjects.enum";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  BASE_URL = '/olympic/users';

  currentUser: User;
  currentUserPromise: Promise<UserCompact>;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.session$.subscribe((session) => {
        this.dropCurrentUser();
    });
  }

  async getUsers(params?: { without_team?: boolean, ids?: string, olympiad_id?: string,
    page?: number, per_page?: number, search_string?: string }) {
    const httpParams = new HttpParams({fromObject: params as any});
    const usersCompact = await this.http.get<UserCompact[]>(`${this.BASE_URL}`, {params: httpParams}).toPromise();
    const users = _map<UserCompact, User>((userCompact) => new UserData(userCompact))(usersCompact);
    _forEach<User>((user) => {
      this.fixUserNameData(user);
    })(users);

    return users;
  }

  async searchUsers(params?: {
    without_team?: boolean, ids?: string, olympiad_id?: string,
    page?: number, per_page?: number, search_string?: string, subject_key?: Subjects
  }) {
    const httpParams = new HttpParams({fromObject: params as any});
    const result = await this.http.get<UserCompact[]>(`${this.BASE_URL}`, {
      params: httpParams,
      observe: 'response'
    }).toPromise();

    const users = _map<UserCompact, User>((userCompact) => new UserData(userCompact))(result.body);
    _forEach<User>((user) => {
      this.fixUserNameData(user);
    })(users);

    return {
      totalPages: result.headers.get('x-pagination-total-pages'),
      users
    };
  }

  // getUserById(id: string) {
  //   return this.http.get<User>(`${this.BASE_URL}/${id}`).toPromise();
  // }

  async getCurrentUser(forceRequest = false, includeRegId?: boolean) {
    if (!this.currentUser || forceRequest) {
      if (!this.currentUserPromise) {
        try {
          let params = null;
          if (includeRegId) {
            params = new HttpParams({fromObject: {with_reg_id: includeRegId} as any});
          }

          const compactUserData = await (this.currentUserPromise = this.http
            .get<UserCompact>(`${this.BASE_URL}/${this.authService.session._id}`, {params}).toPromise());

          this.currentUser = new UserData(compactUserData);
        } catch (e) {
          console.error(e);
        }
      }

      await this.currentUserPromise;
      this.currentUserPromise = null;
      this.fixUserNameData(this.currentUser);
    }

    return this.currentUser;
  }

  fixUserNameData(user: User) {
    const commonname = user.commonname || '';
    const cleanFio = commonname.replace(`(${user.email})`, '');
    const [familyName, firstName, secondName] = cleanFio.replace(/\s+/g, ' ').trim().split(' ');
    user.commonname = cleanFio;
    user.given_name = user.given_name || firstName;
    user.family_name = user.family_name || familyName;
    user.middlename = user.middlename || secondName;
    user.fullName = [
      user.family_name,
      user.given_name,
      user.middlename
    ].join(' ');
  }

  dropCurrentUser() {
    this.currentUser = null;
  }

  async updateUser(userId: string, userData: User) {
    await this.http.put<UserCompact>(`${this.BASE_URL}/${userId}`, UserData.compact(userData)).toPromise();
    this.currentUser = userData;
  }

  async setUserRegId(userId: string, olympiadId: string) {
    return await this.http.post<UserCompact>(`${this.BASE_URL}/${userId}/reg_id`, {
      olympiad_id: olympiadId,
      user_id: userId
    }).toPromise();
  }
}
