import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { filter as _filter, find as _find } from 'lodash/fp';
export class OlympiadSelectorComponent {
    constructor(olympiadService, usersService) {
        this.olympiadService = olympiadService;
        this.usersService = usersService;
    }
    ngOnInit() {
        this.getOlympiads();
    }
    getOlympiads() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const currentUser = yield this.usersService.getCurrentUser();
            const olympiads = yield this.olympiadService.getOlympiads();
            this.olympiads = _filter((olympiad) => {
                return Boolean(_find({ olympiad: olympiad._id })(currentUser.olympiads));
            })(olympiads);
        });
    }
    selectOlympiad(olympiad) {
        this.olympiadService.selectOlympiad(olympiad);
        window.location.reload();
    }
}
