/* tslint:disable:max-line-length */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OlympiadsService } from '@backend/olympiads/olympiads.service';
import { OlympiadFAQ } from '@backend/olympiads/olympiad.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-faq',
  templateUrl: './tab-faq.component.html',
  styleUrls: ['./tab-faq.component.scss']
})
export class TabFaqComponent implements OnInit, OnDestroy {
  public activeId = null;
  show: boolean;

  public faqCollection: OlympiadFAQ[] = [];
  public olympiadSubscription: Subscription;

  constructor(public olympiadService: OlympiadsService) { }

  ngOnInit() {
    this.olympiadSubscription = this.olympiadService.$selectedOlympiadId.subscribe(() => {
      this.faqCollection = this.olympiadService.selectedOlympiad.faq;
    });
  }

  ngOnDestroy() {
    if (this.olympiadSubscription) {
      this.olympiadSubscription.unsubscribe();
    }
  }

  toogleQA(event, id: number) {
    const isOpen = event.target.classList.contains('ic_drop_qa_active');
    isOpen ? this.activeId = null : this.activeId = id;
  }
}
