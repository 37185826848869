import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings } from '@backend/settings/settings.interface';
import { isUndefined as _isUndefined} from 'lodash/fp';
import { OlympiadsService } from '@backend/olympiads/olympiads.service';
import { last as _last, forEach as _forEach } from 'lodash/fp';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  BASE_URL = '/olympic/settings';

  DEFAULT_SETTINGS: Settings = {
    olympiad_id: null,
    registration_available: true,
    video_interview_available: true,
    team_edit_available: true,
    results_tab_available: false
    // second_step_task_upload_available: false
  };

  constructor(private http: HttpClient, private olympiadService: OlympiadsService) {
  }

  async getSettingsList(): Promise<Settings[]> {
    const settingsList = await this.http.get<Settings[]>(`${this.BASE_URL}`).toPromise();

    _forEach<Settings>((settings) => {
      this.fillDefaultSettingValues(settings);
    })(settingsList);


    return settingsList;
  }

  // TODO olympiadId
  async getSettings(olympiadId) {
    const result = await this.http.get<Settings>(`${this.BASE_URL}/${olympiadId}`).toPromise();

    this.fillDefaultSettingValues(result);

    return result;
  }

  async getCurrentSettings(): Promise<Settings> {
    const olympiadId = this.olympiadService.selectedOlympiadId;
    if (!olympiadId) {
      return null;
    }

    return this.getSettings(olympiadId);
  }

  updateSettings(settingsData: Settings) {
    return this.http.put<Settings>(`${this.BASE_URL}`, settingsData);
  }

  fillDefaultSettingValues(settings: Settings) {
    if (_isUndefined(settings.video_interview_available)) {
      settings.video_interview_available = this.DEFAULT_SETTINGS.video_interview_available;
    }

    if (_isUndefined(settings.team_edit_available)) {
      settings.team_edit_available = this.DEFAULT_SETTINGS.team_edit_available;
    }

    // if (_isUndefined(settings.second_step_task_upload_available)) {
    //   settings.second_step_task_upload_available = this.DEFAULT_SETTINGS.second_step_task_upload_available;
    // }
  }
}
