import * as tslib_1 from "tslib";
import { HttpParams } from '@angular/common/http';
import { getOr as _getOr } from 'lodash/fp';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VkProxyService {
    constructor(http) {
        this.http = http;
        this.BASE_URL = '/olympic/vk';
        this.RETRY_COUNT = 3;
        this.RETRY_TIMEOUT = 3000;
        this.reqs = {};
    }
    getCities(params) {
        return this.getAll('/cities', params);
    }
    getCitiesByIds(params) {
        // @ts-ignore
        return this.http.get(this.BASE_URL + '/cities_by_ids', { params }).toPromise();
    }
    getRegions(params) {
        return this.getAll('/regions', params);
    }
    getCountries(params) {
        return this.getAll('/countries', params);
    }
    getAllSchools(params) {
        return this.getAll('/schools', params);
    }
    getSchools(params) {
        const httpParams = new HttpParams({ fromObject: params });
        return this.http.get(this.BASE_URL + '/schools', { params: httpParams }).toPromise();
    }
    getUniversities(params) {
        return this.getAll('/universities', params);
    }
    getFaculties(params) {
        return this.getAllWithRetry('/faculties', params);
    }
    getChairs(params) {
        return this.getAllWithRetry('/chairs', params);
    }
    getAllWithRetry(apiUrl, params = {}, reqId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const currentReqId = reqId || new Date();
            let requestCount;
            if (reqId) {
                requestCount = this.reqs[reqId];
            }
            else {
                this.reqs[currentReqId] = 0;
                requestCount = this.reqs[currentReqId];
            }
            try {
                const res = yield this.getAll(apiUrl, params);
                delete this.reqs[currentReqId];
                return res;
            }
            catch (e) {
                this.reqs[currentReqId] += 1;
                if (requestCount < this.RETRY_COUNT) {
                    yield this.wait(this.RETRY_TIMEOUT);
                    return this.getAllWithRetry(apiUrl, params, currentReqId);
                }
            }
        });
    }
    getAll(apiUrl, params = {}) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const limit = 1000;
                let offset = 0;
                let totalCount = null;
                let allItems = [];
                params.limit = limit;
                params.lang = 'ru';
                while (totalCount === null || offset < totalCount) {
                    params.offset = offset;
                    const data = yield this.http.get(this.BASE_URL + apiUrl, { params }).toPromise();
                    totalCount = data.count || _getOr(0, 'count')(data.response);
                    const items = data.items || _getOr([], 'items')(data.response);
                    allItems = allItems.concat(items);
                    offset += items.length;
                    if (!items.length) {
                        return allItems;
                    }
                }
                return allItems;
            }
            catch (e) {
                throw new Error(e);
            }
        });
    }
    wait(ms) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => setTimeout(resolve, ms));
        });
    }
}
VkProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VkProxyService_Factory() { return new VkProxyService(i0.ɵɵinject(i1.HttpClient)); }, token: VkProxyService, providedIn: "root" });
