import {
  Component, ElementRef, EventEmitter, forwardRef, Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { VkProxyService } from '@backend/vk-proxy/vk-proxy.service';
import { Subject } from 'rxjs';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {VkSchool} from '@backend/vk-proxy/vk-school.interface';
import {VkUniversity} from '@backend/vk-proxy/vk-university.interface';
import {uniqBy as _uniqBy, concat as _concat, flow as _flow} from 'lodash/fp';

@Component({
  selector: 'app-vk-uni-schools-select',
  templateUrl: './vk-uni-schools-select.component.html',
  styleUrls: ['./vk-uni-schools-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VkUniSchoolsSelectComponent),
    }
  ]
})
export class VkUniSchoolsSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() width: string;
  @Input() disabled: boolean;
  @Input() countryId;
  @Input() cityId;
  @Input() universitiesOnly: boolean;
  @Input() schoolsOnly: boolean;
  @Output() organizationChanged = new EventEmitter<VkSchool | VkUniversity>();

  @ViewChild('input', { static: true }) input: ElementRef;

  searchString$: Subject<string> = new Subject<string>();
  searchString: string;
  selectedOrganization: string;
  schools: VkSchool[] = [];
  universities: VkUniversity[] = [];
  organizations: any = [];
  isSearchResultsEmpty = false;

  get model() {
    return this.selectedOrganization;
  }

  set model(value) {
    this.selectedOrganization = value;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private vkProxyService: VkProxyService ) { }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnInit() {
    this.searchString$
      .pipe(
        debounceTime(1000),
        filter((q) => q.length >= 3),
        distinctUntilChanged()
      ).subscribe(async (searchString) => {
        if (!this.universitiesOnly) {
          try {
            this.schools = searchString
              ? await this.vkProxyService.getAllSchools({city_id: this.cityId, q: searchString, count: 500})
              : [];
          } catch (err) {
            this.schools = [];
            console.error(err);
          }
        } else {
          this.schools = [];
        }

        if (!this.schoolsOnly) {
          try {
            this.universities = searchString
              ? await this.vkProxyService.getUniversities({
                country_id: this.countryId || 1,
                city_id: this.cityId,
                q: searchString,
                count: 500
              })
              : [];
          } catch (err) {
            this.universities = [];
            console.error(err);
          }
        } else {
          this.universities = [];
        }

      // @ts-ignore
      this.organizations = this.universities.concat(this.schools);
      this.isSearchResultsEmpty = !this.organizations.length;
    });
  }

  writeValue(value) {
    this.model = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  searchStringChanged($event) {
    this.searchString$.next($event);
  }

  selectOrganization(organization: VkUniversity) {
    this.model = organization.title;
    this.organizationChanged.emit(organization);
  }

  saveSearchString() {
    this.model = this.searchString;
    this.organizationChanged.emit({
      id: null,
      title: this.searchString
    });
  }

  optionListClosed() {

  }

  optionListOpened() {

  }
}
