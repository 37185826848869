import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export interface Toast {
  type: 'info' | 'success' | 'error' | 'warning';
  text: string;
  width?: number;
}

@Injectable({
    providedIn: 'root'
})

export class ToastService {
    private subject = new Subject<Toast>();

    sendToast(data: Toast) {
        this.subject.next(data);
    }

    getToast(): Observable<Toast> {
        return this.subject.asObservable();
    }
}
