import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  cloneDeep as _cloneDeep,
  filter as _filter,
  find as _find,
  forEach as _forEach,
  lowerCase as _lowerCase,
  toString as _toString,
  map as _map
} from 'lodash/fp';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multi-select-autocomplete',
  templateUrl: './multi-select-autocomplete.component.html',
  styleUrls: ['./multi-select-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectAutocompleteComponent),
      multi: true
    }
  ]
})
export class MultiSelectAutocompleteComponent implements OnInit, OnChanges, ControlValueAccessor {

  searchValue = '';
  modelValue = '';
  filteredCollection: any[] = [];
  showOptionsList = false;
  collectionCopy: any[];

  @Input() collection: any[] = [];
  @Input() disabled: boolean;
  @Input() idField?: string;
  @Input() nameField?: string;
  @Input() selectedNameField?: string;
  @Input() placeholder?: string;
  @Input() withAddItemBlock: boolean;
  @Input() addItemLabel: string;
  @Input() addItemButtonLabel: string;
  @Input() allItemsLabel: string;
  @Input() showSearchInput = true;
  @Input() dropdownClassName?: string;
  @Input() maxCheckedCount?: number;

  @Output() addItem?: EventEmitter<any> = new EventEmitter();
  @Output() menuToggle?: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('inputElement', { static: false }) input: ElementRef;
  @ViewChild('selectHeader', { static: false }) selectHeader: ElementRef;

  constructor(protected translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.collectionCopy = _cloneDeep(this.collection);
    this.filteredCollection = this.collectionCopy;
    this.changeValue();
  }

  ngOnInit() {
    this.idField = this.idField || 'id';
    this.nameField = this.nameField || 'name';
    this.selectedNameField = this.selectedNameField || 'name';

    this.translate.get('MULTI_SELECT_AUTOCOMPLETE').subscribe((tr) => {
      this.placeholder = this.placeholder || tr.SELECT_ELEMENT;
      this.addItemLabel = this.addItemLabel || tr.CAN_ADD_ELEMENT;
      this.addItemButtonLabel = this.addItemButtonLabel || tr.ADD_ELEMENT;
      this.allItemsLabel = this.allItemsLabel || tr.ALL;
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  optionListOpened() {
    this.showOptionsList = true;
    this.menuToggle.emit(this.showOptionsList);
    this.selectHeader.nativeElement.style.zIndex = '5000';

    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.focus();
      }
    });
  }

  optionListClosed() {
    this.showOptionsList = false;
    this.selectHeader.nativeElement.style.zIndex = '';
    this.onTouched();
    this.menuToggle.emit(this.showOptionsList);
  }

  changeInputValue(value) {
    this.filterCollection(value);
  }

  filterCollection(substr) {
    this.filteredCollection = _filter((item) => {
      return _lowerCase(item[this.nameField]).indexOf(_lowerCase(substr)) > -1;
    })(this.collectionCopy);
  }

  addItemHandle() {
    this.addItem.emit(this.searchValue);
  }

  writeValue(value) {
    this.modelValue = value;
    this.changeValue();
  }

  get checkedItems() {
    return _filter('checked')(this.collectionCopy);
  }

  get checkedItemIds() {
    return _map(this.idField)(this.checkedItems);
  }

  get checkedItemsCount() {
    return this.checkedItems.length;
  }

  get isAll() {
    return this.collectionCopy.length > 0
      && this.checkedItemsCount === this.collectionCopy.length;
  }

  set isAll(value) {
    if (value) {
      _forEach((item: any) => {
        item.checked = value;
      })(this.collectionCopy);
    }

    if (!value && this.isAll) {
      _forEach((item: any) => {
        item.checked = false;
      })(this.collectionCopy);
    }

    this.onChange(this.checkedItemIds);
  }

  checkItem(item) {
    // if (item.checked && this.maxCheckedCount && this.checkedItemsCount >= this.maxCheckedCount) {
    //   return;
    // }

    this.onChange(this.checkedItemIds);
  }

  uncheckItem(event, item) {
    event.stopPropagation();
    event.preventDefault();
    item.checked = false;
    this.onChange(this.checkedItemIds);
    this.onTouched();
  }

  uncheckAll(event) {
    event.stopPropagation();
    event.preventDefault();
    this.isAll = false;
  }

  protected changeValue() {
    if (!this.modelValue) {
      return;
    }

    const ids = this.modelValue; // .split(',');
    _forEach((id) => {
      const shouldCheck: any = _find((item) => _toString(item[this.idField]) === _toString(id))(this.collectionCopy);
      if (shouldCheck) {
        shouldCheck.checked = true;
      }
    })(ids);
  }
}
