import { Component, OnDestroy, OnInit } from '@angular/core';
import { OlympiadsService } from '@backend/olympiads/olympiads.service';
import { Subscription } from 'rxjs';
import { OlympiadDocument, OlympiadFAQ } from '@backend/olympiads/olympiad.interface';

@Component({
  selector: 'app-tab-docs',
  templateUrl: './tab-docs.component.html',
  styleUrls: ['./tab-docs.component.scss']
})
export class TabDocsComponent implements OnInit, OnDestroy {

  public docsCollection: OlympiadDocument[] = [];
  public olympiadSubscription: Subscription;

  constructor(public olympiadService: OlympiadsService) {
  }


  ngOnInit() {
    this.olympiadSubscription = this.olympiadService.$selectedOlympiadId.subscribe(() => {
      this.docsCollection = this.olympiadService.selectedOlympiad.documents;
    });
  }

  ngOnDestroy() {
    if (this.olympiadSubscription) {
      this.olympiadSubscription.unsubscribe();
    }
  }

}
